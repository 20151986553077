//@flow
import { handleActions } from 'redux-actions';
import createReducerCreator from 'rezz/createReducerCreator';
import set from 'rezz/set';

import Actions from './actions';

export type FirstRequestState = { [string]: ?boolean, ... };

type Action<T: string, D, M> = {
  type: T,
  payload?: D,
  meta?: M,
  ...
};

function progressIndicatorReducer(state: number = 0, action: Action<*, *, *>): number {
  const act: string = action.type;

  switch (act) {
    case Actions.startProgressIndicator.toString():
      return state + 1;
    case Actions.stopProgressIndicator.toString():
      return state > 0 ? state - 1 : 0;
    default:
      return state;
  }
}

const initialState = {};
function createFirstRequestReducer(actions: ?{ ... }) {
  return createReducerCreator<FirstRequestState, _, _>({
    initialState: initialState,
    payloadStrategy: () => true,
    keyStrategy: action => action.payload,
  })(set('PROGRESS_INDICATOR_STOP'), handleActions(actions || {}, initialState));
}

export default {
  progressIndicatorReducer: progressIndicatorReducer,
  createFirstRequestReducer: createFirstRequestReducer,
};
