//@flow
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { type APIResult as FetchResponse } from '@dt/fetch';

export type CreateSupportRequestParams = {
  body: string,
  subject: string,
  ...
};

export async function create(params: CreateSupportRequestParams): FetchResponse<void> {
  if (!params || !params.body || !params.subject) {
    throw new Error('Invalid params supplied to create support request');
  }

  return fetch(`${config.sevenhellApiBaseUrl}/v2/support_requests`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
