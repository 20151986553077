//@flow
import { type LazyQueryHookOptions, type QueryLazyOptions, useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import type { DocumentNode } from 'graphql';

import useBaseQuery, { type UseBaseQueryReturn, type UseQueryOptions } from './useBaseQuery';

type UseLazyQueryReturn<TData, TVariables> = [
  (options?: QueryLazyOptions<TVariables>) => void,
  UseBaseQueryReturn<TData, TVariables>,
];
export default function useQuery<TData, TVariables: { cursor?: ?string, ... } = { cursor?: ?string }>(
  query: DocumentNode,
  apolloOptions?: LazyQueryHookOptions<TData, TVariables>,
  options?: UseQueryOptions<TData> = { ...null },
): UseLazyQueryReturn<TData, TVariables> {
  const [execute, apolloResult] = useApolloLazyQuery<TData, TVariables>(query, {
    notifyOnNetworkStatusChange: true,
    ...apolloOptions,
  });

  const resultsWithFetchMore = useBaseQuery<TData, TVariables>(apolloResult, options);

  return [execute, resultsWithFetchMore];
}
