// @flow
import { useEffect, useState } from 'react';

import type { AssetTag as AssetTagType } from '@dt/graphql-support/types';

type AssetTag = { ...$Diff<AssetTagType, { __typename?: ?string }> };

export default function useAssetTagEditValidation() {
  const [primaryAssetTags, setPrimary] = useState<Array<AssetTag>>([]);
  const [assetTags, setNew] = useState<Array<AssetTag>>([]);

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    setLogs([]);
    // removed assets log
    primaryAssetTags.forEach(assetTag => {
      if (assetTag.tag && !assetTags.find(({ tag, value }) => tag === assetTag.tag && value === assetTag.value)) {
        setLogs(logs => [
          ...logs,
          `Remove tag ${assetTag.tag}${assetTag.value ? `:${assetTag.value}` : ''} from selected asset`,
        ]);
      }
    });
    // apply assets log
    assetTags.forEach(assetTag => {
      if (
        assetTag.tag &&
        !primaryAssetTags.find(({ tag, value }) => tag === assetTag.tag && value === assetTag.value)
      ) {
        setLogs(logs => [
          ...logs,
          `Apply tag ${assetTag.tag}${assetTag.value ? `:${assetTag.value}` : ''} to selected asset.`,
        ]);
      }
    });
  }, [primaryAssetTags, assetTags]);

  return {
    setPrimary,
    setNew,
    logs,
  };
}
