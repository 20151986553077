//@flow
import React from 'react';

export const getDomainFromUrl = (url: string) => {
  const anchor = global.document.createElement('a');
  anchor.href = url;
  return anchor.hostname;
};

type Props = {
  title: ?string,
  domain: string,
  alt?: string,
  size: number,
};

export const ExternalLogo = ({ domain, alt, title, size }: Props) => {
  return (
    <div title={title} style={{ width: size, height: size }}>
      <img alt={alt} src={`https://logo.clearbit.com/${domain}`} width={size} height={size} />
    </div>
  );
};
