//@flow
import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';

import type { SecurityTemplateImportanceTagEnum } from '@dt/enums/SecurityTemplateImportanceTagEnum';
import { default as SecurityTemplateImportanceTagEnumValue } from '@dt/enums/SecurityTemplateImportanceTagEnum';
import { Text } from '@dt/material-components';

export type PopoutFilterPluginStoreBlockerValues = {
  [key: SecurityTemplateImportanceTagEnum]: boolean,
  ...
};

export const PopoutFilterPluginStoreBlockerDisplayLookup: $Exact<
  $ObjMap<typeof SecurityTemplateImportanceTagEnumValue, <V>() => ?string>,
> = {
  [SecurityTemplateImportanceTagEnumValue.APPLE_P1]: 'App Store Blocker',
  [SecurityTemplateImportanceTagEnumValue.GOOGLE_P1]: 'Google Play Store',

  // NOTE: Not all importance tags are accessible from this filter plugin.
  [SecurityTemplateImportanceTagEnumValue.APPLE]: null,
  [SecurityTemplateImportanceTagEnumValue.GOOGLE]: null,
  [SecurityTemplateImportanceTagEnumValue.SECURITY]: null,
  [SecurityTemplateImportanceTagEnumValue.SECURITY_P1]: null,
};

type Props = {
  +value: PopoutFilterPluginStoreBlockerValues,
  +disabled: PopoutFilterPluginStoreBlockerValues,
  +onChange: ((value: PopoutFilterPluginStoreBlockerValues) => PopoutFilterPluginStoreBlockerValues) => void,
};

/*
 * Popout filter plugin.
 * User can select mobile app release types.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginStoreBlocker = ({ value, disabled, onChange }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginStoreBlockerDisplayLookup)
        .filter(key => !!PopoutFilterPluginStoreBlockerDisplayLookup[key])
        .map(key => {
          const val = PopoutFilterPluginStoreBlockerDisplayLookup[key] || '';
          return (
            <FormControlLabel
              key={key}
              label={<Text variant="body">{val}</Text>}
              control={
                <Checkbox
                  color="primary"
                  inputProps={{
                    'aria-label': `${val}`,
                  }}
                  checked={!!value[key]}
                  disabled={!!disabled[key]}
                  onChange={e => {
                    (value =>
                      onChange(prev => ({
                        ...prev,
                        // $FlowFixMe - string -> enum
                        [key]: value,
                      })))(e.target.checked);
                  }}
                />
              }
            />
          );
        })}
    </Box>
  );
};
