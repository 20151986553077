// @flow
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { getUserAccount } from '@dt/session';
import { encodeURIFormComponent } from '@dt/string';
import { mobile_apps, type SearchResults } from '@dt/user-api/openscan';
import { type Saga } from 'redux-saga';
import { all, call, cancel, cancelled, delay, fork, put, spawn, take, takeEvery } from 'redux-saga/effects';

import {
  cancelledSearchResults,
  openscanErrorOccurred,
  openScanSearchNameSelected,
  receivedRequestedSearchResults,
  receivedSearchResult,
  receivedSearchResults,
  requestSearchResult,
  requestSearchResults,
  updateApp,
} from './../actions';

export function* saga(): Saga<void> {
  const userAccount = yield* callPromise(getUserAccount);

  if (userAccount.no_session_reason || !userAccount.accountInfo.toggles || !userAccount.accountInfo.toggles.openscan) {
    return;
  }

  yield spawn(watchForOpenScanSearchNameSelected);
  yield spawn(watchForRequestSearchResults);
  yield spawn(watchForRequestSearchResult);
}

function* watchForOpenScanSearchNameSelected(): Saga<void> {
  yield takeEvery(
    openScanSearchNameSelected.toString(),
    function* (action: { payload: { name: string, ... }, ... }): Saga<void> {
      const { name } = action.payload;
      // eslint-disable-next-line no-console
      console.log(`Searching for '${name}'`);

      yield put(requestSearchResults(name));

      yield call(
        (uri: string) => window.history.replaceState({}, '', uri),
        `/openscan/search/${encodeURIFormComponent(name)}`,
      );
    },
  );
}

function* requestAllSearchResults(searchCriteria, ids): Saga<void> {
  let notReceivedSearchResultIds = ids.slice(0);

  yield all(
    ids.map((id, i) =>
      call(function* () {
        // Used to make saga cancelations easier.
        // Will wait for a specified delayTime before requesting.
        yield delay(i * 350);
        yield put(requestSearchResult(searchCriteria, id));
      }),
    ),
  );

  while (true) {
    const action = yield take(receivedSearchResult.toString());
    const { id } = action.payload;

    notReceivedSearchResultIds = notReceivedSearchResultIds.filter(i => i !== id);

    if (notReceivedSearchResultIds.length <= 0) {
      yield put(receivedRequestedSearchResults(searchCriteria));
      break;
    }
  }
}

function* searchByCriteria(searchCriteria) {
  try {
    const search_results = yield* callPromise(mobile_apps.search, {
      query: `name="${searchCriteria}"`,
    });

    if (search_results && search_results.length > 0) {
      const sortedSearchResults = search_results
        .slice(0)
        // TODO: Filtering of has_results should be removed.
        //       And replaced with requesting a scan for the application.
        .filter(result => result.has_results)
        // TODO: Sorting needs to be pushed back into the backend.
        //       It will allow us to create a more advanced search later.
        .sort(
          (a, b) =>
            (a.name === searchCriteria && b.name === searchCriteria
              ? 0
              : a.name === searchCriteria
                ? -1
                : b.name === searchCriteria
                  ? 1
                  : 0) || a.name.localeCompare(b.name),
        );

      // Try to request the sorted search results in order.
      const ids = sortedSearchResults.map(({ id }) => id);
      yield fork(requestAllSearchResults, searchCriteria, ids);

      yield put(receivedSearchResults(searchCriteria, sortedSearchResults));
      return;
    }

    yield put(receivedSearchResults(searchCriteria, ([]: SearchResults)));
  } catch (err) {
    console.error(err, err.stack);
    yield put(openscanErrorOccurred('Something unexpected occurred.'));
  } finally {
    if (yield cancelled()) {
      yield put(cancelledSearchResults());
    }
  }
}

function* watchForRequestSearchResults(): Saga<void> {
  let searchTask = null;
  yield takeEvery(
    requestSearchResults.toString(),
    function* (action: { payload: { name: string, ... }, ... }): Saga<void> {
      const { name } = action.payload;

      if (searchTask) {
        yield cancel(searchTask);
      }

      searchTask = yield fork(searchByCriteria, name); // eslint-disable-line require-atomic-updates
    },
  );
}

function* watchForRequestSearchResult(): Saga<void> {
  yield takeEvery(
    requestSearchResult.toString(),
    function* (action: {
      payload: {
        search: string,
        id: string,
        ...
      },
      ...
    }): Saga<void> {
      const { search, id } = action.payload;

      try {
        const app = yield* callPromise(mobile_apps.get, id);

        if (app) {
          yield put(updateApp(app));
          yield put(receivedSearchResult(search, id));
        }
      } catch (e) {
        // When an error occurs when fetching an app,
        // don't update the app but notify subscribers that we're done
        // fetching the provided application id.
        yield put(receivedSearchResult(search, id));
      }
    },
  );
}

// TODO@nw: add back in as RESULTS
//////////////////////////////////////////////////////////////////////
/*
export function* watchForSearchAdditionalNamesRequested(): Saga<void> {
  let requested = [];

  yield takeEvery(openscanSearchAdditionalNamesRequested.toString(), function*({
    payload,
  }: {
    payload: string,
  }): Saga<void> {
    if (!requested.includes(payload)) {
      requested.push(payload);
      yield call(searchForAdditionalAppNames, payload);
    }
  });
}

export function* searchForAdditionalAppNames(name: string): Saga<*> {
  try {
    const results = yield* callPromise(external_app_search.get, {
      query: name,
    });

    yield put(openscanReceivedExternalSearchResults(name, results || []));
    return results;
  } catch (err) {
    // HACK: Swallow external search results because these aren't reliable.
    return null;
  }
}
*/
