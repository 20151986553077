//@flow
import paginate, { paginateToEnd } from '@dt/ahura/src/redux/sagas/util/paginate';
import ContactPublisherRequestStateEnum from '@dt/enums/ContactPublisherRequestStateEnum';
import { setTemporaryToken as fetchSetTemporaryToken } from '@dt/fetch';
import { Raven } from '@dt/global';
import { Actions as NotificationsActions } from '@dt/notifications';
import { withProgressIndicator } from '@dt/progress-indicator';
import { callPromise, callSaga, select } from '@dt/redux-saga-wrapped-effects';
import { getUserAccount } from '@dt/session';
import type { OpenScanAlertsFilterConfig } from '@dt/user-api/openscan';
import {
  alerts_filter_config,
  contact_publisher_requests,
  favorite_apps,
  mobile_apps,
  type OpenscanMobileApp,
} from '@dt/user-api/openscan';
import * as support_requests from '@dt/user-api/support_requests';
import { tryAndParseErrorMessage } from '@dt/user-api/util';
import { type Saga } from 'redux-saga';
import { call, put, race, spawn, take, takeEvery } from 'redux-saga/effects';

import {
  addAppToOpenscanWatchListGroup,
  contactPublisherRequest,
  contactPublisherRequestError,
  contactPublisherRequestLoading,
  contactPublisherRequestReceived,
  disabledWatchListInquire,
  openscanConfigReceived,
  openscanErrorOccurred,
  openscanMobileAppsRecieved,
  openscanRuleUpdated,
  openscanWatchlistLoading,
  receivedApp,
  removeAppFromOpenscanWatchList,
  removeAppFromOpenscanWatchListGroup,
  requestApp,
  setTemporaryToken,
  setTemporaryTokenSuccess,
  updateApp,
} from '../actions';
import { appFromId } from '../selectors/openscan';
import { FavoriteAppsEndpoint } from './endpoints';
import {
  watchForOpenscanConfigReceived as watchForPolicyViolationOpenscanConfigReceived,
  watchForOpenscanMobileAppsReceived as watchForPolicyViolationsOpenscanMobileAppsReceived,
} from './policyViolations';

export function* saga(): Saga<void> {
  const { userAccountValue, temporaryToken } = yield race({
    userAccountValue: call(getUserAccount),
    temporaryToken: take(setTemporaryToken.toString()),
  });

  if (temporaryToken) {
    // eslint-disable-next-line no-console
    console.log(`Setting temporary token: ${temporaryToken.payload}`);
    fetchSetTemporaryToken(temporaryToken.payload);

    yield spawn(watchForRequestApp);
    yield put(setTemporaryTokenSuccess());
    return;
  }

  if (
    userAccountValue.no_session_reason ||
    !userAccountValue.accountInfo.toggles ||
    !userAccountValue.accountInfo.toggles.openscan
  ) {
    return;
  }
  yield spawn(watchForRequestApp);
  yield spawn(watchForPolicyViolationsOpenscanMobileAppsReceived);
  yield spawn(watchForPolicyViolationOpenscanConfigReceived);
  yield spawn(watchForAddAppToWatchlistGroup);
  yield spawn(watchForRemoveAppFromWatchlistGroup);
  yield spawn(watchForRemoveAppFromWatchlist);
  yield spawn(watchForRuleUpdate);
  yield spawn(watchForContactPublisherRequest);
  yield spawn(watchForDisabledWatchListInquire);
  yield spawn(watchForErrors);
  if (window.location.pathname.startsWith('/openscan')) {
    yield spawn(loadAllWatchlistApps);
    yield spawn(loadOpenscanConfigAtStart);
  }
}

export function* watchForRequestApp(): Saga<void> {
  yield takeEvery(requestApp.toString(), function* (action: { payload: { id: string, ... }, ... }): Saga<void> {
    const { id } = action.payload;

    try {
      const app = yield* callPromise(mobile_apps.get, id);

      if (app) {
        yield put(updateApp(app));
        yield put(receivedApp(id));
      }
    } catch (err) {
      // TODO@nw: Notification for loading app.
    }
  });
}

export function* watchForAddAppToWatchlistGroup(): Saga<void> {
  yield takeEvery(
    addAppToOpenscanWatchListGroup.toString(),
    function* ({
      payload,
    }: {
      payload: {
        id: string,
        group: string,
        ...
      },
      ...
    }): Saga<void> {
      try {
        const app = yield* callPromise(favorite_apps.add, payload.id, payload.group);
        if (!app) {
          throw new Error('Could not load openscan app');
        }
        yield put(updateApp(app));
      } catch (err) {
        yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
      }
    },
  );
}

export function* watchForRemoveAppFromWatchlist(): Saga<void> {
  yield takeEvery(
    removeAppFromOpenscanWatchList.toString(),
    function* ({ payload }: { payload: { id: string, ... }, ... }): Saga<void> {
      try {
        const app = yield* callPromise(favorite_apps.remove, payload.id);
        if (!app) {
          throw new Error('Could not remove app');
        }
        yield put(updateApp(app));
      } catch (err) {
        yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
      }
    },
  );
}

export function* watchForRemoveAppFromWatchlistGroup(): Saga<void> {
  yield takeEvery(
    removeAppFromOpenscanWatchListGroup.toString(),
    function* ({
      payload,
    }: {
      payload: {
        id: string,
        group: string,
        ...
      },
      ...
    }): Saga<void> {
      try {
        const app = yield* callPromise(favorite_apps.remove, payload.id, payload.group);
        if (!app) {
          throw new Error('Could not remove app from favorites');
        }
        yield put(updateApp(app));
      } catch (err) {
        yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
      }
    },
  );
}

export function* watchForContactPublisherRequest(): Saga<void> {
  yield takeEvery(
    contactPublisherRequest.toString(),
    function* ({
      payload,
    }: {
      payload: { id: string, additional_recipients: $ReadOnlyArray<string>, ... },
      ...
    }): Saga<void> {
      try {
        let app = yield* select(appFromId(payload.id), {});

        if (!app) {
          throw new Error('This openscan app was not found /1');
        }

        yield put(
          contactPublisherRequestLoading([
            {
              ...app,
              contactPublisherRequestState: ContactPublisherRequestStateEnum.LOADING,
            },
          ]),
        );

        yield* callPromise(contact_publisher_requests.create, {
          mobile_app_id: payload.id,
          additional_recipients: payload.additional_recipients,
        });
        app = yield* select(appFromId(payload.id), {});

        if (!app) {
          throw new Error('This openscan app was not found /2');
        }

        yield put(
          contactPublisherRequestReceived([
            {
              ...app,
              contactPublisherRequestState: ContactPublisherRequestStateEnum.RECEIVED,
            },
          ]),
        );
      } catch (err) {
        let app = yield* select(appFromId(payload.id), {});

        if (!app) {
          throw new Error('This openscan app was not found /2');
        }

        yield put(
          contactPublisherRequestError([
            {
              ...app,
              contactPublisherRequestState: ContactPublisherRequestStateEnum.ERROR,
            },
          ]),
        );
        yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
      }
    },
  );
}

const favoriteAppsPageGetter = function* (params?: { +cursor?: ?string, ... }) {
  return yield* callPromise(favorite_apps.list, params || { cursor: '' });
};

function* getWatchlistApps(): Saga<void | $ReadOnlyArray<OpenscanMobileApp>> {
  try {
    yield put(openscanWatchlistLoading());

    const response = yield* callSaga(paginate, FavoriteAppsEndpoint, {}, favoriteAppsPageGetter);

    if (response) {
      yield put(openscanMobileAppsRecieved(response.openscan_mobile_apps || []));
      return response.openscan_mobile_apps;
    } else {
      yield put(openscanErrorOccurred('An unknown error occurred (OpenScan Initialize)'));
    }
  } catch (e) {
    yield put(openscanErrorOccurred(tryAndParseErrorMessage(e)));
  }
}

export function* loadAllWatchlistApps(): Saga<void> {
  return yield* withProgressIndicator(function* () {
    yield* callSaga(paginateToEnd, getWatchlistApps, FavoriteAppsEndpoint, {}, {});
  }, FavoriteAppsEndpoint);
}

export function* loadOpenscanConfigAtStart(): Saga<void> {
  yield* withProgressIndicator(function* () {
    try {
      const config = yield* callPromise(alerts_filter_config.get);

      yield put(openscanConfigReceived(config));
    } catch (err) {
      yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
    }
  }, 'openscan');
}

export function* watchForRuleUpdate(): Saga<void> {
  yield takeEvery(
    openscanRuleUpdated.toString(),
    function* ({ payload }: { payload: { config: OpenScanAlertsFilterConfig, ... }, ... }): Saga<void> {
      try {
        const { config } = payload;
        const newConfig = yield* callPromise(alerts_filter_config.replace, config);
        yield put(openscanConfigReceived(newConfig));
      } catch (err) {
        yield put(openscanErrorOccurred(tryAndParseErrorMessage(err)));
      }
    },
  );
}

export function* watchForDisabledWatchListInquire(): Saga<void> {
  yield takeEvery(
    disabledWatchListInquire.toString(),
    function* ({ payload }: { payload: { app: OpenscanMobileApp, ... }, ... }): Saga<void> {
      try {
        const currentUser = yield* select(state => state.currentUser);

        const user = {
          first_name: 'N/A',
          last_name: 'N/A',
          login_email: 'N/A',
          role: 'N/A',
          ...currentUser,
        };

        const app = {
          name: 'N/A',
          platform: 'N/A',
          bundle_id: 'N/A',
          ...payload.app,
        };

        const body = `
    First Name: ${user.first_name}
    Last Name: ${user.last_name}
    Notification Email: ${user.notification_email || user.login_email}
    Role: ${user.role}
    App Name: ${app.name} (${app.platform})
    Bundle ID: ${app.bundle_id}
  `;

        yield* callPromise(support_requests.create, {
          subject: 'Disabled WatchList',
          body,
        });

        yield put(
          NotificationsActions.requestNotifyUser({
            text: 'A representative from Data Theorem will be contacting you shortly.',
          }),
        );
      } catch (err) {
        Raven.captureException(err, {
          extra: { msg: 'watchForDisabledWatchListInquire in inquire saga' },
        });

        yield put(
          NotificationsActions.requestNotifyUser({
            text: 'There was an error. Please contact support@datatheorem.com',
          }),
        );

        throw err;
      }
    },
  );
}

export function* watchForErrors(): Saga<void> {
  yield takeEvery(openscanErrorOccurred.toString(), function* ({ payload }): Saga<void> {
    if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test')
      console.warn('An error has occured in a saga=', payload);
    yield put(
      NotificationsActions.requestNotifyUser({
        text: 'An error has occured. Please contact support@datatheorem.com',
      }),
    );
  });
}
