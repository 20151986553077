// @flow
import { values } from '@dt/functions';
import { PolicyRuleTypeRelevance, type PolicyViolation, type PolicyViolationStatus } from '@dt/horizon-api';
import compareDesc from 'date-fns/compare_desc';
import { createSelector } from 'reselect';
import { createObjectSelector } from 'reselect-map';

import { apiOperations } from '../api_operations/selectors';
import { getAllCertificateChainsDecorated } from '../certificate_chains/selectors';
import { cloudResources } from '../cloud_resources/util';
import { domain_names } from '../domain_names/selectors';
import { graphql_apis } from '../graphql_apis/util';
import { network_services } from '../network_services/util';
import { getAllPolicyRulesDecorated } from '../policy_rules/selectors';
import { restfulApis } from '../restful_apis/util';
import type { State } from '../store_state_type';
import { users_list } from '../users/selectors';
import { web_applications } from '../web_applications/util';
import {
  type PolicyViolationDecorated,
  type PolicyViolationDecoratedList,
  type PolicyViolationForEventList,
  type PolicyViolationWithAffectedComponent,
  type PolicyViolationWithAffectedComponentList,
} from './types';
import {
  decorate,
  decorateForEvent,
  decorateWithAffectedComponent,
  policy_violations,
  unresolvedRelevanceOrder,
} from './util';

const getAllTasksAsObject = createObjectSelector<State, { ... }, ?PolicyViolationDecorated, _, _>(
  state => state.policy_violations.id,
  getAllPolicyRulesDecorated,
  (policy_violation, policy_rules_decorated) => decorate(policy_violation, policy_rules_decorated),
);

export const getAllTasks = createSelector<State, { ... }, PolicyViolationDecoratedList, _, _, _, _, _, _>(
  getAllTasksAsObject,
  policy_violations => values(policy_violations).filter(Boolean).sort(unresolvedRelevanceOrder),
);

const getAllTasksWithAffectedComponentsAsObject = createObjectSelector<
  State,
  { ... },
  ?PolicyViolationWithAffectedComponent,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
>(
  state => state.policy_violations.id,
  getAllPolicyRulesDecorated,
  restfulApis,
  apiOperations,
  network_services,
  cloudResources,
  web_applications,
  graphql_apis,
  domain_names,
  getAllCertificateChainsDecorated,

  (
    policy_violation,
    policy_rules_decorated,
    restful_apis,
    api_operations,
    network_services,
    cloud_resources,
    web_applications,
    graphql_apis,
    domain_names,
    certificate_chains_decorated,
  ) => {
    const policy_violation_decorated = decorate(policy_violation, policy_rules_decorated);
    if (!policy_violation_decorated) return null;
    return decorateWithAffectedComponent(
      policy_violation_decorated,
      restful_apis,
      api_operations,
      network_services,
      cloud_resources,
      web_applications,
      graphql_apis,
      domain_names,
      certificate_chains_decorated,
    );
  },
);

export const getAllTasksWithAffectedComponents = createSelector<
  State,
  { ... },
  PolicyViolationWithAffectedComponentList,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
>(
  getAllTasksWithAffectedComponentsAsObject,

  policy_violations_decorated => values(policy_violations_decorated).filter(Boolean).sort(unresolvedRelevanceOrder),
);

export const getAllTasksForEvents = createSelector<State, { ... }, PolicyViolationForEventList, _, _>(
  getAllTasks,
  users_list,

  (policy_violations, users) =>
    policy_violations
      .map(policy_violation => decorateForEvent(policy_violation, users))
      .filter(Boolean)
      .sort(unresolvedRelevanceOrder),
);

export const getDecoratedViolationsFromRuleId = createSelector<
  State,
  {| policy_rule_id: string, status?: PolicyViolationStatus |},
  PolicyViolationWithAffectedComponentList,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
>(
  (state, props = {}) => props.policy_rule_id,
  (state, props = {}) => props.status,
  policy_violations,
  getAllPolicyRulesDecorated,
  restfulApis,
  apiOperations,
  network_services,
  cloudResources,
  web_applications,
  graphql_apis,
  domain_names,
  getAllCertificateChainsDecorated,

  (
    policy_rule_id,
    policy_violated_status,
    policy_violations,
    policy_rules_decorated,
    restful_apis,
    api_operations,
    network_services,
    cloud_resources,
    web_applications,
    graphql_apis,
    domain_names,
    certificate_chains_decorated,
  ) => {
    let policy_violations_filter: PolicyViolation[] = [];

    for (const id of policy_violations.for_violated_policy_rule_id[policy_rule_id] || []) {
      if (policy_violations.id[id]) {
        policy_violations_filter.push(policy_violations.id[id]);
      }
    }

    const policy_violations_decorated = policy_violations_filter
      .map(policy_violation => decorate(policy_violation, policy_rules_decorated))
      .filter(Boolean);

    return policy_violations_decorated
      .filter(violation => (policy_violated_status ? violation.status === policy_violated_status : true))
      .map(policy_violation_decorated =>
        decorateWithAffectedComponent(
          policy_violation_decorated,
          restful_apis,
          api_operations,
          network_services,
          cloud_resources,
          web_applications,
          graphql_apis,
          domain_names,
          certificate_chains_decorated,
        ),
      )
      .filter(Boolean);
  },
);

export const getDecoratedTaskFromId = createSelector<
  State,
  { id?: ?string, ... },
  ?PolicyViolationWithAffectedComponent,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
>(
  (state, props = {}) => props.id,
  policy_violations,
  getAllPolicyRulesDecorated,
  restfulApis,
  apiOperations,
  network_services,
  cloudResources,
  web_applications,
  graphql_apis,
  domain_names,
  getAllCertificateChainsDecorated,

  (
    policy_violation_id,
    policy_violations,
    policy_rules_decorated,
    restful_apis,
    api_operations,
    network_services,
    cloud_resources,
    web_applications,
    graphql_apis,
    domain_names,
    certificate_chains_decorated,
  ) => {
    const policy_violation = policy_violations.id[policy_violation_id || ''];
    if (!policy_violation) return;

    const policy_violation_decorated = decorate(policy_violation, policy_rules_decorated);

    if (!policy_violation_decorated) return;

    return decorateWithAffectedComponent(
      policy_violation_decorated,
      restful_apis,
      api_operations,
      network_services,
      cloud_resources,
      web_applications,
      graphql_apis,
      domain_names,
      certificate_chains_decorated,
    );
  },
);

export const getActionItems = createSelector<State, { ... }, PolicyViolationWithAffectedComponentList, _>(
  getAllTasksWithAffectedComponents,
  tasks =>
    tasks
      .filter(task => task.policy_rule_decorated.relevance === PolicyRuleTypeRelevance.URGENT && !task.date_resolved)
      .sort((a, b) => compareDesc(a.date_created, b.date_created))
      .slice(0, 2),
);
