//@flow
import { type QueryHookOptions, useQuery as useApolloQuery } from '@apollo/client';
import type { DocumentNode } from 'graphql';

import useBaseQuery, { type UseBaseQueryReturn, type UseQueryOptions } from './useBaseQuery';

export default function useQuery<TData, TVariables: { cursor?: ?string, ... } = { cursor?: ?string }>(
  query: DocumentNode,
  apolloOptions?: QueryHookOptions<TData, TVariables>,
  options?: UseQueryOptions<TData> = { ...null },
): UseBaseQueryReturn<TData, TVariables> {
  const apolloResult = useApolloQuery<TData, TVariables>(query, {
    notifyOnNetworkStatusChange: true,
    ...apolloOptions,
  });

  const resultsWithFetchMore = useBaseQuery<TData, TVariables>(apolloResult, options);

  return resultsWithFetchMore;
}
