//@flow
import { useRef } from 'react';

import { type QueryResult } from '@apollo/client';

import defaultNextCursorSelector from './defaultNextCursorSelector';
import makeFetchMore, { type NewFetchMore } from './makeFetchMore';

export type UseQueryOptions<TData> = {
  cursorSelector?: (?TData) => null | string,
};

export type UseBaseQueryReturn<TData, TVariables> = {
  ...QueryResult<TData, TVariables>,
  pageNumber: number,
  fetchMore: null | NewFetchMore<TData, TVariables>,
};

export default function useBaseQuery<TData, TVariables: { cursor?: ?string, ... } = { cursor?: ?string }>(
  queryResult: QueryResult<TData, TVariables>,
  options: UseQueryOptions<TData>,
): UseBaseQueryReturn<TData, TVariables> {
  const pageNumber = useRef(0);

  const next_cursor_selector =
    options && typeof options.cursorSelector === 'function' ? options.cursorSelector : defaultNextCursorSelector;
  const next_cursor = queryResult.data ? next_cursor_selector(queryResult.data) : null;

  const prevCursor = useRef<null | string>(null);
  const cursorChanged = prevCursor.current !== next_cursor;
  prevCursor.current = next_cursor;

  const fetchMore = useRef<null | NewFetchMore<TData, TVariables>>(null);

  if (cursorChanged && next_cursor) {
    ++pageNumber.current;
    fetchMore.current = makeFetchMore(queryResult.data, queryResult.fetchMore, next_cursor);
  } else if (!next_cursor) {
    fetchMore.current = null;
  }

  return {
    ...queryResult,
    pageNumber: pageNumber.current,
    fetchMore: fetchMore.current,
  };
}
