// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';
import qs from 'query-string';

import type {
  DomainNameList,
  EventsList,
  NetworkServicesList,
  PaginatedResponse,
  PolicyRuleList,
  PolicyRuleTypeList,
  PolicyViolationList,
  RestfulAPIsList,
  WebApplicationList,
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type WebApplicationsListResponse = {|
  web_applications: WebApplicationList,
  network_services: NetworkServicesList,
  domain_names: DomainNameList,
  policy_violations: PolicyViolationList,
  policy_rules: PolicyRuleList,
  policy_rule_types: PolicyRuleTypeList,
|};
export async function list(params: { ... }): FetchResponse<PaginatedResponse<WebApplicationsListResponse>> {
  return fetch(`${api}/${version}/web_applications?${qs.stringify(params)}`).then(parse);
}

type WebApplicationDetailsResponse = {|
  ...WebApplicationsListResponse,
  events: EventsList,
  restful_apis: RestfulAPIsList,
|};

export async function details(id: string): FetchResponse<WebApplicationDetailsResponse> {
  return fetch(`${api}/${version}/web_applications/${id}`).then(parse);
}
