// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';

import type { NetworkServicesList, OpenAPIDefinitionsList, RestfulAPIsList } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type OpenAPIDefinitionCreateParams = string;

export type OpenAPIDefinitionsResponse = {
  openapi_definitions: OpenAPIDefinitionsList,
  ...
};

export async function list(): FetchResponse<OpenAPIDefinitionsResponse> {
  return fetch(`${api}/${version}/openapi_definitions`).then(parse);
}

type OpenAPIDefinitionCreateResponseError = {
  title: string,
  description: string,
  ...
};

export type OpenAPIDefinitionCreateResponse =
  | {
      network_services: NetworkServicesList,
      restful_apis: RestfulAPIsList,
      ...
    }
  | OpenAPIDefinitionCreateResponseError;

export async function create(params: OpenAPIDefinitionCreateParams): FetchResponse<OpenAPIDefinitionCreateResponse> {
  return fetch(`${api}/${version}/openapi_definitions`, {
    method: 'POST',
    body: params,
    headers: { 'Content-Type': 'text/plain' },
  }).then(parse);
}
