//@flow
export function values<T, K: string>(o: { [K]: void | T, ... }): $ReadOnlyArray<T> {
  return Object.keys(o)
    .map<void | T>(k => o[k])
    .filter(Boolean);
}

export function generateTextFile(content: string, name?: string) {
  const element = document.createElement('a');
  const file = new Blob([content], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = name ? name : 'scan-log.txt';
  document.body && document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

/**
 * truncate a given number and adds "K", 'M", etc to end of it
 * some examples => 10000 -> 10K, 3293604 -> 3.3M
 * @param {given number} num
 * @param {decimal point} fixed
 */
export function truncateNumber(num: number, fixed: number) {
  const fixedValue = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const b = num.toPrecision(2).split('e'); // get power
  const k = b.length === 1 ? 0 : Math.floor(Math.min(Number(String(b[1]).slice(1)), 14) / 3); // floor at decimals, ceiling at trillions
  const c = Number(k < 1 ? num.toFixed(0 + fixedValue) : (num / Math.pow(10, k * 3)).toFixed(1 + fixedValue)); // divide by power
  const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
  const e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
}

/**
 * returns the given number if it isn't NaN, otherwise return 0
 * @param {given number} num
 */
export function NaNOrInfinityAsZero(num: number) {
  if (Number.isNaN(num)) {
    return 0;
  }
  if (!Number.isFinite(num)) {
    return 0;
  }
  return num;
}

export function safeParse(value: ?string) {
  try {
    return value ? JSON.parse(value) : null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function capitalize(str: ?string) {
  return (str || '')?.charAt(0).toUpperCase() + (str || '')?.slice(1);
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const formatUtc = (date: Date | string) => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;

  const hours = dateObj.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};
