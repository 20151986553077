//@flow
import { callSaga } from '@dt/redux-saga-wrapped-effects';
import type { Saga } from 'redux-saga';
import { put } from 'redux-saga/effects';

import Actions from './actions';

/*
 * Context manager for controlling the progress indicator within other sagas. Although you could start and stop the
 * progress indicator yourself, this is prone to error. Instead, you can use this generator to ensure that stop is
 * eventually called even if an exception is raised.
 *
 *
 *     let response;
 *
 *     yield manageProgress(function* () {
 *         response = yield // ...;
 *     });
 */
export default function* withProgressIndicator<R>(inner: () => Saga<R>, payload?: ?string): Saga<R> {
  let result;
  // We want errors to propogate
  yield put(Actions.startProgressIndicator(payload));
  try {
    result = yield* callSaga(inner);
  } finally {
    yield put(Actions.stopProgressIndicator(payload));
  }

  return result;
}
