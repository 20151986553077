//@flow
import config from '@dt/config';
import type { CompliancePolicyEnum } from '@dt/enums/CompliancePolicyEnum';
import type { FindingPriorityEnum } from '@dt/enums/FindingPriorityEnum';
import type { FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';
import type { ReleaseType as ReleaseTypeEnum } from '@dt/enums/MobileAppReleaseTypeEnum';
import type { SecurityTemplateImportanceTagStoreBlockersEnum } from '@dt/enums/SecurityTemplateImportanceTagEnum';
import type { SecurityTemplateSeverityEnum } from '@dt/enums/SecurityTemplateSeverityEnum';
import fetch, { parse } from '@dt/fetch';
import qs from 'query-string';
import { array, boolean, number, object, string } from 'yup';

import type { PaginatedResponse } from './_common';
import { result } from './util';

// region Typing
export type DateRange = {
  type: string,
  // Date ISO 8601: "2017-01-31T14:09:07.313240"
  from_date?: ?string,
  to_date?: ?string,
  ...
};

export const findingSortChoiceObject: {|
  PRIORITY: 'PRIORITY',
  SEVERITY: 'SEVERITY',
  STATUS: 'STATUS',
  DATE_CREATED: 'DATE_CREATED',
  AGE: 'AGE',
  NONE: 'NONE',
|} = {
  PRIORITY: 'PRIORITY',
  SEVERITY: 'SEVERITY',
  STATUS: 'STATUS',
  DATE_CREATED: 'DATE_CREATED',
  AGE: 'AGE',
  NONE: 'NONE',
};

export type FindingSortChoiceEnum = $Keys<typeof findingSortChoiceObject>;

export type SearchFilterQuery = {
  keywords: ?string,
  priorities: $ReadOnlyArray<FindingPriorityEnum>,
  severities: $ReadOnlyArray<SecurityTemplateSeverityEnum>,
  store_blockers: $ReadOnlyArray<SecurityTemplateImportanceTagStoreBlockersEnum>,
  compliance_policies: $ReadOnlyArray<CompliancePolicyEnum>,
  statuses: {
    statuses: $ReadOnlyArray<FindingTargetStatusEnum>,
    date_range: DateRange,
    ...
  },
  current_statuses: $ReadOnlyArray<FindingTargetStatusEnum>,
  mobileAppIds: string[],
  release_types: $ReadOnlyArray<ReleaseTypeEnum>,
  min_age: number,
  sort_by: string,
  ...
};

export type SearchQuery = {
  id: string,
  is_owner: boolean,
  is_private: boolean,
  title: string,
  description: string,
  filter_query: SearchFilterQuery,
  ...
};

export type SearchQueries = $ReadOnlyArray<SearchQuery>;
// endregion

// region Type Validation
const SearchFilterQuerySchema = object().shape({
  keywords: string().nullable(),
  priorities: array().of(string()).ensure(),
  severities: array().of(string()).ensure(),
  store_blockers: array().of(string()).ensure(),
  compliance_policies: array().of(string()).ensure(),
  statuses: object().shape({
    statuses: array().of(string()).ensure(),
    date_range: object().shape({
      from_date: string().nullable(),
      to_date: string().nullable(),
      type: string(),
    }),
  }),
  current_statuses: array().of(string()).ensure(),
  release_types: array().of(string()).ensure(),
  min_age: number(),
  sort_by: string(),
});

const SearchQuerySchema = object().shape({
  id: string(),
  is_owner: boolean().required(),
  is_private: boolean().required(),
  title: string().required(),
  description: string().required(),
  filter_query: SearchFilterQuerySchema.required(),
});

const SearchQueryListResponseSchema = object().shape({
  search_queries: array().of(SearchQuerySchema).ensure(),
  pagination_information: object().shape({
    next_cursor: string().nullable(),
    total_count: string().nullable(),
  }),
});
// endregion

export type ListResponse = PaginatedResponse<{
  search_queries: SearchQueries,
  ...
}>;

export type CreateSearchFilterQueryRequest = {
  +priorities: $ReadOnlyArray<FindingPriorityEnum>,
  +severities: $ReadOnlyArray<SecurityTemplateSeverityEnum>,
  +store_blockers: $ReadOnlyArray<SecurityTemplateImportanceTagStoreBlockersEnum>,
  +compliance_policies: $ReadOnlyArray<CompliancePolicyEnum>,
  +statuses: ?{|
    +statuses: $ReadOnlyArray<FindingTargetStatusEnum>,
    +date_range: DateRange,
  |},
  +current_statuses: $ReadOnlyArray<FindingTargetStatusEnum>,
  +release_types: $ReadOnlyArray<ReleaseTypeEnum>,
  +min_age: ?number,
  +sort_by: string,
  ...
};

export async function create(params: {
  is_private: boolean,
  title: string,
  description: string,
  filter_query: CreateSearchFilterQueryRequest,
  ...
}): Promise<SearchQuery> {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

export async function get(id: string): Promise<SearchQuery> {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`)
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

export type ListParams = { +cursor?: ?string, ... };

export async function list(params: ListParams): Promise<ListResponse> {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/search_queries${
      params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
    }`,
  )
    .then(parse)
    .then(result)
    .then(response => SearchQueryListResponseSchema.validate(response));
}

export async function patch(
  id: string,
  params: {
    is_private?: ?boolean,
    title?: ?string,
    description?: ?string,
    filter_query?: SearchFilterQuery,
    ...
  },
): Promise<SearchQuery> {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  })
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

export async function remove(id: string) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`, {
    method: 'DELETE',
  }).then(parse);
}
