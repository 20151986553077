//@flow
import React, { Component, type Node } from 'react';
import { Link, Route } from 'react-router-dom';

import MenuItem from './Item';

type Props = {
  path?: string,
  exact?: boolean,
  strict?: boolean,
  replace?: boolean,
  // For MenuItem
  children?: Node,
  active?: boolean,
  label: Node,
  heading?: boolean,
  badge?: ?string,
  onClick?: () => void,
  open?: boolean,
  hasSubMenu?: ?boolean,
  isSubMenu?: ?boolean,
  isChildApp?: ?boolean,
  tooltip?: boolean,
};

export class MenuLinkItemReactRouter extends Component<Props> {
  render() {
    const { path, exact, strict, tooltip, replace, hasSubMenu, isSubMenu, isChildApp, ...appMenuItemProps } =
      this.props;

    return (
      <Route path={path} exact={exact} strict={strict}>
        {({ match }) => {
          if (path) {
            return (
              <Link to={path} replace={replace} style={{ display: 'block' }}>
                <MenuItem
                  tooltip={tooltip}
                  hasSubMenu={hasSubMenu}
                  isSubMenu={isSubMenu}
                  isChildApp={isChildApp}
                  active={!!match}
                  {...appMenuItemProps}
                />
              </Link>
            );
          }

          return <MenuItem {...appMenuItemProps} />;
        }}
      </Route>
    );
  }
}
