// @flow
import type { Node } from 'react';
import React, { forwardRef, memo } from 'react';

type Props = {
  children?: Node,
  target?: '_self' | '_blank' | '_parent' | '_top',
  to: string,
  style?: Object,
  ...
};

function ExtLink({ to, children, target = '_blank', style, ...rest }: Props, ref) {
  return (
    <a {...rest} ref={ref} href={to} target={target} rel="noopener noreferrer" style={{ outline: 0, ...style }}>
      {children}
    </a>
  );
}

export default memo<Props>(forwardRef<Props, _>(ExtLink));
