//@flow
import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';

import type { FindingPriority } from '@dt/graphql-support/types';
import { FindingPriorityValues } from '@dt/graphql-support/types';
import { Text } from '@dt/material-components';

export type PopoutFilterPluginFindingPriorityValues = {
  [key: FindingPriority]: boolean,
  ...
};

export const PopoutFilterPluginFindingPriorityDisplayLookup: $Exact<
  $ObjMap<typeof FindingPriorityValues, <V>() => string>,
> = {
  [FindingPriorityValues.NO_PRIORITY]: 'NO_PRIORITY',
  [FindingPriorityValues.P0]: 'P0',
  [FindingPriorityValues.P1]: 'P1',
  [FindingPriorityValues.P2]: 'P2',
  [FindingPriorityValues.P3]: 'P3',
  [FindingPriorityValues.P4]: 'P4',
};

type Props = {
  +value: PopoutFilterPluginFindingPriorityValues,
  +disabled: PopoutFilterPluginFindingPriorityValues,
  +onChange: ((value: PopoutFilterPluginFindingPriorityValues) => PopoutFilterPluginFindingPriorityValues) => void,
};

/*
 * Popout filter plugin.
 * User can select finding priorities.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginFindingPriority = ({ value, disabled, onChange }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginFindingPriorityDisplayLookup).map(key => {
        const val = PopoutFilterPluginFindingPriorityDisplayLookup[key];
        return (
          <FormControlLabel
            key={key}
            label={<Text variant="body">{val}</Text>}
            control={
              <Checkbox
                color="primary"
                inputProps={{
                  'aria-label': `${val}`,
                }}
                checked={!!value[key]}
                disabled={!!disabled[key]}
                onChange={e => {
                  (value =>
                    onChange(prev => ({
                      ...prev,
                      // $FlowFixMe - string -> enum
                      [key]: value,
                    })))(e.target.checked);
                }}
              />
            }
          />
        );
      })}
    </Box>
  );
};
