//@flow
import React, { memo } from 'react';

import { useMutation } from '@apollo/client';
import shared_links from '@dt/graphql-support/horizon/shared_links';
import type {
  SharedLinksCreateForPolicyViolationMutation,
  SharedLinksCreateForPolicyViolationMutationVariables,
} from '@dt/graphql-support/types/SharedLinksCreateForPolicyViolationMutation';

import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

type Props = {|
  +policy_violation_id: string,
|};

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share policy violations.
 *
 * @param policy_violation_id - Used with the graphql create when creating a shared link
 *
 * @example
 *     <SharedLinksButtonPolicyViolation policy_violation_id={policy_violation.id} />
 */
const SharedLinksButtonPolicyViolationComponent = function SharedLinksButtonPolicyViolation({
  policy_violation_id,
}: Props) {
  const [createSharedLinkForPolicyViolation] = useMutation<
    SharedLinksCreateForPolicyViolationMutation,
    SharedLinksCreateForPolicyViolationMutationVariables,
  >(shared_links.create_for_policy_violation);

  /*
   * Handle creating a shared link for policy violations.
   */
  const handleOnCreate = async validity_duration => {
    // Create shared link.
    const { data, errors } = await createSharedLinkForPolicyViolation({
      variables: {
        id: policy_violation_id,
        body: {
          validity_duration,
        },
      },
    });

    // Error occurred, let shared links button render an error.
    if (!data || errors) {
      throw new Error();
    }

    // Success, let shared links button render page url.
    const violation_shared_id = data.shared_links_for_policy_violation_create.shared_resource_id || policy_violation_id;
    return `${window.location.origin}/api/share/${data.shared_links_for_policy_violation_create.access_token}/violations/${violation_shared_id}`;
  };

  return (
    <SharedLinksButtonCommon
      manageShareLink="/api/shared-links"
      title={`Create a Secure Link to Violation #${policy_violation_id.substr(0, 8)}`}
      onCreate={handleOnCreate}
      useReachRouter
    >
      <Banner status="warning" inline icon={<></>}>
        The secure link will allow temporary access to this policy violation.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonPolicyViolation = memo<Props>(SharedLinksButtonPolicyViolationComponent);
