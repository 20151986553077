// @flow
import { useMutation } from '@apollo/client';
import assets from '@dt/graphql-support/horizon/assets';
import type {
  AssetListQuery,
  AssetListQueryVariables,
  AssetTagsPutMutation,
  AssetTagsPutMutationVariables,
} from '@dt/graphql-support/types';

export default function useAssetTagEditSave(assetId: string, onCompleted: () => void) {
  const [assetTagsModify, { loading, error }] = useMutation<AssetTagsPutMutation, AssetTagsPutMutationVariables>(
    assets.asset_tags.put,
    {
      update: (cache, result) => {
        if (result?.errors) {
          return null;
        }

        // update asset tags get query
        cache.writeQuery({
          query: assets.asset_tags.get,
          variables: {
            uuid: assetId,
          },
          data: {
            asset_tags: {
              __typename: 'AssetTagsResponse',
              asset_tags: result.data.asset_tags_put.asset_tags,
            },
          },
        });

        // get list of all params cached for asset query
        const asset_list_queries = Object.keys(cache.data.data.ROOT_QUERY).filter(key => key.includes('asset_list'));
        const asset_list_query_variables = asset_list_queries.map(key =>
          JSON.parse(key.match(/\((.*)\)/)?.pop() || '{}'),
        );

        // update asset tags for selected asset in inventory table
        asset_list_query_variables
          .filter(variables => variables.filter_by_asset_tags)
          .forEach(variables => {
            let q = cache.readQuery<AssetListQuery, AssetListQueryVariables>({
              query: assets.list,
              variables: {
                ...variables,
              },
            });
            cache.writeQuery({
              query: assets.list,
              variables: {
                ...variables,
              },
              data: {
                asset_list: {
                  ...q?.asset_list,
                  assets: q?.asset_list?.assets.map(asset => {
                    if (assetId === asset.id) {
                      return {
                        ...asset,
                        tags: result.data.asset_tags_put.asset_tags,
                      };
                    }
                    return asset;
                  }),
                },
              },
            });
          });
      },
      onCompleted: () => {
        onCompleted();
      },
    },
  );

  return {
    saveQuery: assetTagsModify,
    loading,
    error,
  };
}
