// @flow
import { createAction } from 'redux-actions';

const startProgressIndicator = createAction<'PROGRESS_INDICATOR_START', [?string], ?string>(
  'PROGRESS_INDICATOR_START',
  type => type,
);
const stopProgressIndicator = createAction<'PROGRESS_INDICATOR_STOP', [?string], ?string>(
  'PROGRESS_INDICATOR_STOP',
  type => type,
);

export default {
  startProgressIndicator: startProgressIndicator,
  stopProgressIndicator: stopProgressIndicator,
};
