//@flow
import qs from 'query-string';
import { createSelector } from 'reselect';

export type SearchParam = { +location?: { +search?: ?string, ... }, ... };

export const search = createSelector<
  { ... },
  SearchParam,
  null | { [string]: void | string | $ReadOnlyArray<string> | null, ... },
  _,
>(
  (state, props) =>
    props && typeof props.location === 'object' && typeof props.location.search == 'string'
      ? props.location.search
      : null,

  search => {
    if (!search) {
      return null;
    }

    return qs.parse(search);
  },
);
