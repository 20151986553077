//@flow
export default async function (src: string) {
  return new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.onload = resolve;
    el.onerror = reject;
    el.src = src;

    const existingScript = document.getElementsByTagName('script')[0];
    if (!existingScript || !existingScript.parentNode) {
      if (process.env.NODE_ENV === 'test') {
        return;
      }

      throw new Error('No existing script tags found in document');
    }
    existingScript.parentNode.insertBefore(el, existingScript);
  });
}
