//@flow
import type { TargetAdditionalContext, TargetStatus } from '@dt/findings/types';
import { array, object, string, type YupObject } from 'yup';

export const TargetStatusSchema: YupObject<TargetStatus> = object().shape({
  status: string().required(),
  date: string().required(),
});

const TargetAdditionalContextSchema: YupObject<{
  type: string,
  body: string,
}> = object().shape({
  type: string().required(),
  body: string().required(),
});

export const TargetSchema: YupObject<{
  id: string,
  formatted_text: string,
  statuses: $ReadOnlyArray<TargetStatus>,
  additional_contexts?: $ReadOnlyArray<TargetAdditionalContext>,
  ...
}> = object().shape({
  id: string().required(),
  formatted_text: string().required(),
  statuses: array().of(TargetStatusSchema).required(),
  additional_contexts: array().of(TargetAdditionalContextSchema),
});
