// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';

import { type PaginatedResponse } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export const SharedLinksResourceType = {
  CLOUD_AUTHENTICATORS_ADD: 'CLOUD_AUTHENTICATORS_ADD',
  SINGLE_POLICY_VIOLATION_READ: 'SINGLE_POLICY_VIOLATION_READ',
  SINGLE_NETWORK_SERVICE_READ: 'SINGLE_NETWORK_SERVICE_READ',
  SINGLE_RESTFUL_API_READ: 'SINGLE_RESTFUL_API_READ',
  SINGLE_CLOUD_RESOURCE_READ: 'SINGLE_CLOUD_RESOURCE_READ',
  SINGLE_WEB_APPLICATION_READ: 'SINGLE_WEB_APPLICATION_READ',
  CROSS_PRODUCT_ONBOARDING: 'CROSS_PRODUCT_ONBOARDING',
  SINGLE_GRAPHQL_API_READ: 'SINGLE_GRAPHQL_API_READ',
  FILES_UPLOAD_READ_WRITE: 'FILES_UPLOAD_READ_WRITE',
  MULTI_POLICY_VIOLATIONS_READ: 'MULTI_POLICY_VIOLATIONS_READ',
};

export type SharedLinksResourceTypeEnum = $Keys<typeof SharedLinksResourceType>;

export type SharedLinks = {|
  id: string,
  shared_resource_type: SharedLinksResourceTypeEnum,
  shared_resource_id: null | string,
  access_token: string,
  date_expires: string,
  allows_access_to_related_policy_violations: boolean,
|};

export type SharedLinksList = $ReadOnlyArray<SharedLinks>;

type SharedLinksResponse = {|
  shared_links: SharedLinksList,
|};

export function list(): FetchResponse<PaginatedResponse<SharedLinksResponse>> {
  return fetch(`${api}/${version}/shared_links`).then(parse);
}

export function remove(id: string): Promise<boolean> {
  return fetch(`${api}/${version}/shared_links/${id}`, {
    method: 'DELETE',
  }).then(res => res.ok);
}

export type SharedLinkCreateParams = {
  validity_duration: number,
};

export type SharedLinkCreateResponse = {
  +shared_resource_id: null | string,
  +shared_resource_type: SharedLinksResourceTypeEnum,
  +access_token: string,
};

export function create_for_authenticators(params: SharedLinkCreateParams): FetchResponse<SharedLinkCreateResponse> {
  return fetch(`${api}/${version}/cloud_authenticators/shared_links`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export function create_for_violation_id(
  violation_id: string,
  params: SharedLinkCreateParams,
): FetchResponse<SharedLinkCreateResponse> {
  return fetch(`${api}/${version}/policy_violations/${violation_id}/shared_links`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export function create_for_web_application_id(
  web_application_id: string,
  params: {
    ...SharedLinkCreateParams,
    allows_access_to_related_policy_violations: boolean,
  },
): FetchResponse<SharedLinkCreateResponse> {
  return fetch(`${api}/${version}/web_applications/${web_application_id}/shared_links`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export function create_for_cloud_resource_id(
  cloud_resource_id: string,
  params: {
    ...SharedLinkCreateParams,
    allows_access_to_related_policy_violations: boolean,
  },
): FetchResponse<SharedLinkCreateResponse> {
  return fetch(`${api}/${version}/cloud_resources/${cloud_resource_id}/shared_links`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export function create_for_restful_api_id(
  restful_api_id: string,
  params: {
    ...SharedLinkCreateParams,
    allows_access_to_related_policy_violations: boolean,
  },
): FetchResponse<SharedLinkCreateResponse> {
  return fetch(`${api}/${version}/restful_apis/${restful_api_id}/shared_links`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export function create_for_graphql_api_id(
  graphql_api_id: string,
  params: {
    ...SharedLinkCreateParams,
    allows_access_to_related_policy_violations: boolean,
  },
): FetchResponse<SharedLinkCreateResponse> {
  return fetch(`${api}/${version}/graphql_apis/${graphql_api_id}/shared_links`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export function create_for_network_service_id(
  network_service_id: string,
  params: {
    ...SharedLinkCreateParams,
    allows_access_to_related_policy_violations: boolean,
  },
): FetchResponse<SharedLinkCreateResponse> {
  return fetch(`${api}/${version}/network_services/${network_service_id}/shared_links`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
