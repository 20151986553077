// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';
import qs from 'query-string';

import { type CloudResourcesListResponse } from './cloud_resources';
import { type NetworkServicesListResponse } from './network_services';
import { type RESTfulAPIResponse } from './restful_apis';
import { type PaginatedResponse } from './types';
import { type WebApplicationsListResponse } from './web_applications';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export const AssetTypes = {
  NETWORK_SERVICE: 'NETWORK_SERVICE',
  RESTFUL_API: 'RESTFUL_API',
  CLOUD_RESOURCE: 'CLOUD_RESOURCE',
  WEB_APPLICATION: 'WEB_APPLICATION',
};

export type AssetTypeEnum = $Keys<typeof AssetTypes>;

type SearchQueryType = {
  text: string,
  asset_types: $ReadOnlyArray<AssetTypeEnum>,
  cloud_resource_types: $ReadOnlyArray<string>,
  hosted_on_cloud_providers: $ReadOnlyArray<string>,
  discovered_via_aws_authenticator_ids: $ReadOnlyArray<string>,
  discovered_via_gcp_authenticator_ids: $ReadOnlyArray<string>,
  discovered_via_azure_authenticator_ids: $ReadOnlyArray<string>,
  discovered_via_axway_authenticator_ids: $ReadOnlyArray<string>,
  discovered_via_apigee_authenticator_ids: $ReadOnlyArray<string>,
  discovered_via_apigee_on_prem_authenticator_ids: $ReadOnlyArray<string>,
  discovered_via_mulesoft_authenticator_ids: $ReadOnlyArray<string>,
  discovered_via_kong_authenticator_ids: $ReadOnlyArray<string>,
};

type AssetSearchType = {|
  id: string,
  date_created: string,
  created_by_user_id: string,
  search_query: SearchQueryType,
|};

type AssetSearchResponse = {|
  assets_searches: $ReadOnlyArray<AssetSearchType>,
|};

export async function create(params: { ... }): FetchResponse<AssetSearchResponse> {
  return fetch(`${api}/${version}/assets_searches`, {
    method: 'POST',
    body: JSON.stringify({
      search_query: params,
    }),
  }).then(parse);
}

export const network_services = {
  list: async function (id: string, params?: { ... }): FetchResponse<PaginatedResponse<NetworkServicesListResponse>> {
    return fetch(`${api}/${version}/assets_searches/${id}/network_services?${qs.stringify(params || {})}`).then(parse);
  },
};

export const restful_apis = {
  list: async function (id: string, params?: { ... }): FetchResponse<PaginatedResponse<RESTfulAPIResponse>> {
    return fetch(`${api}/${version}/assets_searches/${id}/restful_apis?${qs.stringify(params || {})}`).then(parse);
  },
};

export const web_applications = {
  list: async function (id: string, params?: { ... }): FetchResponse<PaginatedResponse<WebApplicationsListResponse>> {
    return fetch(`${api}/${version}/assets_searches/${id}/web_applications?${qs.stringify(params || {})}`).then(parse);
  },
};

export const cloud_resources = {
  list: async function (id: string, params?: { ... }): FetchResponse<PaginatedResponse<CloudResourcesListResponse>> {
    return fetch(`${api}/${version}/assets_searches/${id}/cloud_resources?${qs.stringify(params || {})}`).then(parse);
  },
};
