// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type InventoryStatsListResponse = {|
  +inventory_stats: InventoryStatsList,
|};

type AssetsInventoryStats = {|
  +assets_total_count: number,

  // We classify each asset based on the worst violation that's affecting it
  +urgent_assets_count: number,
  +important_assets_count: number,
  +proactive_assets_count: number,

  +shadow_assets_count: number,
|};

type CloudResourcesInventoryStats = {|
  // Serverless is a subset of all cloud resources
  ...AssetsInventoryStats,
  +serverless_assets_count: number,
  +shadow_serverless_assets_count: number,
|};

type RestfulApisInventoryStats = {|
  ...AssetsInventoryStats,
  // Each API contains a bunch of API operations
  +api_operations_total_count: number,
|};

export type SingleCloudInventoryStats = {|
  +network_services_stats: AssetsInventoryStats,
  +restful_apis_stats: RestfulApisInventoryStats,
  +web_applications_stats: AssetsInventoryStats,
  +cloud_resources_stats: CloudResourcesInventoryStats,
|};

export type InventoryStats = {|
  +reference_date: string,

  +all_assets_stats: SingleCloudInventoryStats,

  +aws_assets_stats: SingleCloudInventoryStats,
  +gcp_assets_stats: SingleCloudInventoryStats,
  +azure_assets_stats: SingleCloudInventoryStats,
  +apigee_assets_stats: SingleCloudInventoryStats,
  +mulesoft_assets_stats: SingleCloudInventoryStats,

  +events_total_count: number,
  +scans_total_count: number,

  +unresolved_policy_violations_total_count: number,
  +resolved_policy_violations_total_count: number,
|};

export type InventoryStatsList = $ReadOnlyArray<InventoryStats>;

export async function list(reference_date?: string): FetchResponse<InventoryStatsListResponse> {
  return fetch(`${api}/${version}/inventory_stats${reference_date ? '/' + reference_date : ''} `).then(parse);
}
