//@flow
import React, { type Node, PureComponent } from 'react';

import { Raven } from '@dt/global';

import ErrorPage from './ErrorPage';

type Props = { children: Node, ... };

type State = { hasError: boolean, ... };

export default class ErrorBoundary extends PureComponent<Props, State> {
  state = { hasError: false };

  componentDidCatch(error: Error, { componentStack }: { componentStack: string, ... }) {
    console.error(error);
    this.setState({ hasError: true });
    Raven.captureException(error, {
      extra: { msg: componentStack },
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}
