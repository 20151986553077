//@flow
import config from '@dt/config';
import { type FindingPriorityEnum } from '@dt/enums/FindingPriorityEnum';
import { type FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';
import fetch, { parse } from '@dt/fetch';
import type { Note } from '@dt/findings/types';
import { type SecurityFinding } from '@dt/findings/types';
import type { TargetStatusWithDate } from '@dt/graphql-support/types';
import qs from 'query-string';
import { array, object, string } from 'yup';

import { type PaginatedResponse } from './_common';
import { TargetSchema } from './_targets';
import { check, result } from './util';

const NoteSchema = object().shape({
  date_updated: string(),
  text: string(),
  security_finding_id: string(),
  author_email: string(),
  date: string(),
  date_created: string(),
  id: string(),
});

const SecurityFindingSchema = object().shape({
  id: string().required(),
  notes: array().of(NoteSchema).ensure(),
  targets: array().of(TargetSchema).required(),
});

async function validate(finding: SecurityFinding): Promise<SecurityFinding> {
  return SecurityFindingSchema.validate(finding);
}

async function validateList(container: ListResponse): Promise<ListResponse> {
  return object()
    .shape({
      security_findings: array().of(SecurityFindingSchema),
    })
    .validate(container);
}

export async function get(id: string): Promise<SecurityFinding> {
  if (!id || !id.length) {
    throw new Error('Invalid security finding ID provided');
  }

  return fetch(`${config.sevenhellApiBaseUrl}/v2/security_findings/${id}`).then(parse).then(result).then(validate);
}

type ListParams = {
  +cursor?: ?string,
  +mobile_app_id?: ?string,
  +priority?: ?string,
  +status_group?: ?string,
  +template_id?: ?string,
  +updated_since?: ?string,
  +compliance_policy?: ?string,
  +issue_type_id?: ?string,
  ...
};

type ListResponse = PaginatedResponse<{
  security_findings: $ReadOnlyArray<SecurityFinding>,
  ...
}>;

export async function list(params?: ListParams): Promise<ListResponse> {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/security_findings${
      params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
    }`,
  )
    .then(parse)
    .then(result)
    .then(validateList);
}

type PatchParams = {
  priority: ?FindingPriorityEnum,
  aggregated_status: ?FindingTargetStatusEnum,
  is_permanently_closed: ?boolean,
  ...
};

export async function patch(id: string, params: PatchParams): Promise<SecurityFinding> {
  if (!id || !id.length) {
    throw new Error('Invalid finding ID provided');
  }

  if (!params) {
    throw new Error('A priority, aggregated_status or is_permanently_closed param is required');
  }

  // These values have to be removed from the patch params if null for now otherwise leading to corrupt data.
  // Sevenhell will be updated to ignore null values.
  if (typeof params.is_permanently_closed === 'undefined' || params.is_permanently_closed === null) {
    delete params['is_permanently_closed'];
  }

  if (typeof params.aggregated_status === 'undefined' || params.aggregated_status === null) {
    delete params['aggregated_status'];
  }

  if (typeof params.priority === 'undefined' || params.priority === null) {
    delete params['priority'];
  }

  if (params.priority != null) {
    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/security_findings/${id}${
        params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
      }`,
      {
        method: 'PATCH',
      },
    )
      .then(parse)
      .then(result);
  } else {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/security_findings/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(params),
    })
      .then(parse)
      .then(result);
  }
}

type CreateStatusParams = { status: FindingTargetStatusEnum, ... };

export const targets = {
  statuses: {
    async create(security_finding_id: string, id: string, params: CreateStatusParams): Promise<TargetStatusWithDate> {
      if (!security_finding_id || !security_finding_id.length) {
        throw new Error('Invalid security finding ID provided');
      }

      if (!id || !id.length) {
        throw new Error('Invalid target IDs provided');
      }

      if (!params || !params.status) {
        throw new Error('A valid status is required');
      }

      return fetch(
        `${config.sevenhellApiBaseUrl}/v2/security_findings/${security_finding_id}/targets/${id}/statuses${
          params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
        }`,
        {
          method: 'POST',
        },
      )
        .then(parse)
        .then(result);
    },
  },
  jira_ticket: {
    async create(security_finding_id: string, target_id: string): Promise<SecurityFinding> {
      if (!security_finding_id || !security_finding_id.length) {
        throw new Error('Invalid security finding ID provided');
      }
      if (!target_id || !target_id.length) {
        throw new Error('Invalid target ID provided');
      }

      return fetch(
        `${config.sevenhellApiBaseUrl}/v2/security_findings/${security_finding_id}/targets/${target_id}/jira_ticket`,
        {
          method: 'POST',
        },
      )
        .then(parse)
        .then(result);
    },
  },
};

type CreateNoteParams = {
  +text: string,
  +is_question_for_datatheorem: boolean,
  +is_internal_comment: boolean,
  author_email?: string,
};

export const notes = {
  async create(security_finding_id: string, params: CreateNoteParams): Promise<Note> {
    if (!security_finding_id || !security_finding_id.length) {
      throw new Error('Invalid security finding ID provided');
    }
    if (!params || !params.text) {
      throw new Error('Note text is required');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/security_findings/${security_finding_id}/notes`, {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(parse)
      .then(result);
  },

  async del(security_finding_id: string, id: string): Promise<void> {
    if (!security_finding_id || !security_finding_id.length) {
      throw new Error('Invalid security finding ID provided');
    }
    if (!id || !id.length) {
      throw new Error('Invalid note ID provided');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/security_findings/${security_finding_id}/notes/${id}`, {
      method: 'DELETE',
    })
      .then(parse)
      .then(check);
  },
};
