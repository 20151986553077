// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type UserSession = {|
  +session_token: string,
  +user_id: string,
  +customer_name: string,
|};

export type CreateSessionParams = { session_token: string, ... };

export async function create(params: CreateSessionParams): FetchResponse<UserSession> {
  return fetch(`${api}/${version}/user_sessions`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
