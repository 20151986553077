// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';
import qs from 'query-string';

import {
  type CertificateChainList,
  type CertificateList,
  type DomainNameList,
  type EventsList,
  type NetworkService,
  type NetworkServicesList,
  type PaginatedResponse,
  type PolicyRuleList,
  type PolicyRuleTypeList,
  type PolicyViolationList,
  type RestfulAPIsList,
  type TlsScanResultList,
  type WebApplicationList,
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type NetworkServicesListResponse = {|
  +network_services: NetworkServicesList,
  +domain_names: DomainNameList,
  +policy_violations: PolicyViolationList,
  +policy_rules: PolicyRuleList,
  +policy_rule_types: PolicyRuleTypeList,
|};

export type NetworkServiceDetailsResponse = {|
  ...NetworkServicesListResponse,
  +certificates: CertificateList,
  +certificate_chains: CertificateChainList,
  +restful_apis: RestfulAPIsList,
  +tls_scan_results: TlsScanResultList,
  +events: EventsList,
  +web_applications: WebApplicationList,
|};

export async function list(params: { ... }): FetchResponse<PaginatedResponse<NetworkServicesListResponse>> {
  return fetch(`${api}/${version}/network_services?${qs.stringify(params)}`).then(parse);
}

export async function details(id: string): FetchResponse<NetworkServiceDetailsResponse> {
  return fetch(`${api}/${version}/network_services/${id}`).then(parse);
}

type CreateNetworkServiceParams = {|
  +domain_name: string,
  +port: number,
  +application_layer_protocol: 'HTTP' | 'UNKNOWN',
  +is_tls_encrypted: boolean,
|};

export async function create(params: CreateNetworkServiceParams): FetchResponse<NetworkService> {
  return fetch(`${api}/${version}/network_services`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
