// @flow
import { type Saga } from 'redux-saga';
import { all, call, takeEvery } from 'redux-saga/effects';

import { getAllCloudResourcesWhileOnVisualization } from '../cloud_resources/sagas';
import { getAllNetworkServicesWhileOnVisualization } from '../network_services/sagas';
import { takePatternAndFetch } from '../resource_fetch/sagas';
import { getAllRestfulAPIsWhileOnVisualization } from '../restful_apis/sagas';
import { getCertificateTransparencyDomains } from './../configurations/resource_fetch.sagas';
import {
  dashboardOverviewMounted,
  dashboardShadowAssetCounterMounted,
  environmentMapMounted,
  globalStatsMounted,
  requestInventoryStats,
} from './actions';
import {
  getInventoryStats,
  // getInventoryStatsRequest,
} from './resource_fetch.sagas';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(takePatternAndFetch, 'mounted/dashboard*', function* (action): Saga<void> {
      const calls = [];

      if (action.type === dashboardOverviewMounted.toString()) {
        calls.push(call(getInventoryStats, {}));
        calls.push(call(getCertificateTransparencyDomains, {}));
      }

      if (action.type === environmentMapMounted.toString()) {
        calls.push(call(getAllCloudResourcesWhileOnVisualization, {}));
        calls.push(call(getAllRestfulAPIsWhileOnVisualization, {}));
        calls.push(call(getAllNetworkServicesWhileOnVisualization, {}));
      }

      if (action.type === dashboardShadowAssetCounterMounted.toString()) {
        calls.push(call(getInventoryStats, {}));
      }

      yield all(calls);
    }),
    call(takePatternAndFetch, globalStatsMounted.toString(), function* (): Saga<void> {
      yield call(getInventoryStats, {});
    }),
    takeEvery(requestInventoryStats.toString(), function* (): Saga<void> {
      yield call(getInventoryStats, {});
    }),
  ]);
}
