// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadPurchaseQuoteAction, purchaseQuoteApiSecureSelectPlan } from './redux/actions';
import { type State } from './types';
import { formatMoney } from './utls';

export const usePurchaseQuote = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadPurchaseQuoteAction());
  }, [dispatch]);

  const { name, status, purchaseQuote } = useSelector((state: State) => state.BillingInfo);

  let shouldShow = purchaseQuote.shouldShow;
  if (status !== 'demo' || !purchaseQuote.shouldShow) {
    return {
      shouldShow: false,
    };
  }

  const subscriptionDiscount = (() => {
    return purchaseQuote.subscriptions.list.filter(item => item.id === purchaseQuote.subscriptions.selected_id)[0]
      .discountPresentage;
  })();

  const otherDiscounts = (() => {
    let total = 0;
    purchaseQuote.discounts.map(item => {
      if (item.selected) {
        total += item.discountPresentage;
      }
    });
    return total;
  })();

  const total_selected_apps = purchaseQuote.app_list.filter(item => item.checked).length;

  const selected_app_list: typeof purchaseQuote.app_list = purchaseQuote.app_list.filter(item => item.checked);

  const selected_discount_list: typeof purchaseQuote.discounts = purchaseQuote.discounts.filter(item => item.selected);

  const totalDiscounts = subscriptionDiscount + otherDiscounts;

  const totalAppsPrice = total_selected_apps * 1500;

  const apiSecurePrice = (() => {
    const selectedPlan = purchaseQuote.api_secure.plans.filter(
      item => item.id == purchaseQuote.api_secure.selected_plan_id,
    );
    return selectedPlan.length ? selectedPlan[0].price : 0;
  })();

  const totalPrice = (() => {
    return totalAppsPrice + apiSecurePrice - (totalDiscounts * (totalAppsPrice + apiSecurePrice)) / 100;
  })();

  if (purchaseQuote.api_secure.recommended_plan_id > 0 && purchaseQuote.api_secure.selected_plan_id === null) {
    dispatch(purchaseQuoteApiSecureSelectPlan(purchaseQuote.api_secure.recommended_plan_id));
  } else if (purchaseQuote.api_secure.selected_plan_id === null) {
    dispatch(purchaseQuoteApiSecureSelectPlan(0));
  }

  const api_secure_selected_plan = (() => {
    const selectedPlan = purchaseQuote.api_secure.plans.filter(
      item => item.id == purchaseQuote.api_secure.selected_plan_id,
    );
    if (selectedPlan.length) {
      return {
        name: selectedPlan[0].name,
        restful_apis: selectedPlan[0].restfulApiOperations,
        api_ops: selectedPlan[0].restfulApiOperations,
        web_applications: selectedPlan[0].webApps,
        dbs: selectedPlan[0].storageDb,
        serverless: selectedPlan[0].serverlessApps,
      };
    }
    return {
      name: 'No Subscription',
      restful_apis: 0,
      api_ops: 0,
      web_applications: 0,
      dbs: 0,
      serverless: 0,
    };
  })();

  const selected_duration = (() => {
    return purchaseQuote.subscriptions.list.filter(item => item.id === purchaseQuote.subscriptions.selected_id)[0].id;
  })();

  return {
    ...purchaseQuote,
    name,
    shouldShow,
    total_selected_apps,
    selected_app_list,
    selected_discount_list,
    api_secure_selected_plan,
    selected_duration,
    totalPrice: formatMoney(totalPrice),
    totalAnnuallyPrice: formatMoney(totalPrice * 12),
    totalAppsPrice: formatMoney(totalAppsPrice),
    totalApiSecurePrice: formatMoney(apiSecurePrice),
    totalDiscountAmmount: formatMoney(totalAppsPrice + apiSecurePrice - totalPrice),
    totalAnnualDiscountAmmount: formatMoney((totalAppsPrice + apiSecurePrice - totalPrice) * 12),
    api_secure_stats: purchaseQuote.api_secure.stats,
    totalDiscounts,
  };
};
