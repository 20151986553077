//@flow
import React from 'react';

import UnknownIcon from '@mui/icons-material/HelpOutline';

import { palette } from '@dt/theme';

import AwsLogo from './svg/aws_logo.svg';
import AxwayLogo from './svg/axway_logo.svg';
import AzureLogo from './svg/azure_logo.svg';
import GcpLogo from './svg/gcp_logo.svg';
import KongLogo from './svg/kong_logo.svg';
import MulesoftLogo from './svg/mulesoft_logo.svg';
import Tag from './Tag';

type Props = {|
  cloud_resource_decorated: {
    aws_arn: null | string,
    gcp_project_id: null | string,
    azure_tenant_id: null | string,
    axway_org_id: null | string,
    mulesoft_domain: null | string,
    kong_workspace_name: null | string,
    ...
  },
|};

const ICON_SIZE = 14;

function TagCloudResourceId({
  cloud_resource_decorated: {
    aws_arn,
    gcp_project_id,
    azure_tenant_id,
    axway_org_id,
    mulesoft_domain,
    kong_workspace_name,
  },
}: Props) {
  /*
   * NOTE: Not every cloud resource has a corresponding resource id at this time.
   *       These are the ones that are currently not supported.
   *
   *       - APIGEE
   *
   * The unknown cases should never happen, if it does the cloud resource needs to be inspected on the backend for bugs.
   */
  const { label, icon } = (function () {
    if (aws_arn) {
      return {
        label: `${aws_arn}`, // NOTE: ARN is included in aws_arn.
        icon: <img height={ICON_SIZE} width={ICON_SIZE} src={AwsLogo} alt="AWS icon" />,
      };
    } else if (gcp_project_id) {
      return {
        label: `PROJECT: ${gcp_project_id}`,
        icon: <img height={ICON_SIZE} width={ICON_SIZE} src={GcpLogo} alt="GCP icon" />,
      };
    } else if (azure_tenant_id) {
      return {
        label: `TENANT ID: ${azure_tenant_id}`,
        icon: <img height={ICON_SIZE} width={ICON_SIZE} src={AzureLogo} alt="Azure icon" />,
      };
    } else if (axway_org_id) {
      return {
        label: `ORG ID:${axway_org_id}`,
        icon: <img height={ICON_SIZE} width={ICON_SIZE} src={AxwayLogo} alt="Axway icon" />,
      };
    } else if (mulesoft_domain) {
      return {
        label: `DOMAIN: ${mulesoft_domain}`,
        icon: <img height={ICON_SIZE} width={ICON_SIZE} src={MulesoftLogo} alt="Mulesoft icon" />,
      };
    } else if (kong_workspace_name) {
      return {
        label: `WORKSPACE: ${kong_workspace_name}`,
        icon: <img height={ICON_SIZE} width={ICON_SIZE} src={KongLogo} alt="Kong icon" />,
      };
    }
    return {
      label: 'RESOURCE ID: unknown',
      icon: <UnknownIcon style={{ height: ICON_SIZE, width: ICON_SIZE, color: palette.gray }} />,
    };
  })();

  return <Tag icon={icon} label={label} />;
}

export default React.memo<Props>(TagCloudResourceId);
