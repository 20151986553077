//@flow
import { type ActionType, handleActions } from 'redux-actions';

import Actions from './actions';

export type NotificationsState = $ReadOnlyArray<Notification>;

export type Notification = {
  text: string,
  severity?: 'error' | 'success' | 'warning' | 'info',
  ...
};

export default handleActions<
  NotificationsState,
  ActionType<typeof Actions.requestNotifyUser> | ActionType<typeof Actions.dismissNotificationClicked>,
>(
  {
    [Actions.requestNotifyUser.toString()]: (state, { payload: { notification } }) => state.concat(notification),
    [Actions.dismissNotificationClicked.toString()]: (state, { payload: { notification } }) =>
      state.filter(current => current !== notification),
  },
  [],
);
