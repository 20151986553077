//@flow
import React from 'react';

import Avatar from '@mui/material/Avatar';

import { PolicyRuleTypeRelevance, type PolicyRuleTypeRelevanceType } from '@dt/horizon-api';
import { type OldPaletteToNewPaletteType, palette } from '@dt/theme';

import PolicyViolationIcon from './PolicyViolationIcon';

const PolicyViolationStatusToPaletteColorMap = {
  [PolicyRuleTypeRelevance.URGENT]: palette.red,
  [PolicyRuleTypeRelevance.IMPORTANT]: palette.yellow,
  [PolicyRuleTypeRelevance.PROACTIVE]: palette.blue,
  /* No dedicated style yet */
  [PolicyRuleTypeRelevance.INFORMATIONAL]: palette.blue,
  RESOLVED: palette.green,
};

const RelevanceToType: {
  +[PolicyRuleTypeRelevanceType]: OldPaletteToNewPaletteType,
  ...
} = {
  [PolicyRuleTypeRelevance.URGENT]: 'bad',
  [PolicyRuleTypeRelevance.IMPORTANT]: 'warning',
  [PolicyRuleTypeRelevance.PROACTIVE]: 'info',
  /* No dedicated style yet */
  [PolicyRuleTypeRelevance.INFORMATIONAL]: 'info',
};

type Props = {|
  relevance: ?$Keys<typeof RelevanceToType>,
  huge?: boolean,
  tiny?: boolean,
  white?: boolean,
|};

export default React.memo<Props>(function RelevanceAvatar({ relevance, ...props }: Props) {
  return (
    <Avatar
      style={{
        backgroundColor: relevance
          ? PolicyViolationStatusToPaletteColorMap[relevance]
          : PolicyViolationStatusToPaletteColorMap['RESOLVED'],
        display: 'inline-flex',
        width: props.tiny ? 24 : 32,
        height: props.tiny ? 24 : 32,
      }}
    >
      <PolicyViolationIcon color={palette.white} type={relevance ? RelevanceToType[relevance] : 'good'} {...props} />
    </Avatar>
  );
});
