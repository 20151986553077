//@flow
import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';

import type { AssetTypeEnum } from '@dt/graphql-support/types';
import { AssetTypeEnumValues } from '@dt/graphql-support/types';

export type PopoutFilterPluginAssetTypeValues = {
  [key: AssetTypeEnum]: boolean,
  ...
};

export const PopoutFilterPluginAssetTypeDisplayLookup: $Exact<$ObjMap<typeof AssetTypeEnumValues, <V>() => string>> = {
  [AssetTypeEnumValues.DOMAIN_NAME]: 'Domain Name',
  [AssetTypeEnumValues.GRAPHQL_API]: 'GraphQL API',
  [AssetTypeEnumValues.RESTFUL_API]: 'RESTful API',
  [AssetTypeEnumValues.API_OPERATION]: 'API Operation',
  [AssetTypeEnumValues.CLOUD_RESOURCE]: 'Cloud Resource',
  [AssetTypeEnumValues.WEB_APPLICATION]: 'Web Application',
  [AssetTypeEnumValues.NETWORK_SERVICE]: 'Network Service',
  [AssetTypeEnumValues.MOBILE_APPLICATION]: 'Mobile Application',
  [AssetTypeEnumValues.MOBILE_SDK]: 'Mobile SDK',
  [AssetTypeEnumValues.GRPC_METHOD]: 'gRPC Method',
  [AssetTypeEnumValues.GRPC_SERVICE]: 'gRPC Service',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: 'Kubernetes Cluster',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: 'Kubernetes Cluster Component',
  [AssetTypeEnumValues.SOAP_API]: 'SOAP API',
  [AssetTypeEnumValues.SOAP_API_OPERATION]: 'SOAP API Operation',
  [AssetTypeEnumValues.REPOSITORY]: 'Repository',
  [AssetTypeEnumValues.CLOUD_IDENTITY]: 'Cloud Identity',
};

type Props = {
  +value: PopoutFilterPluginAssetTypeValues,
  +onChange: ((value: PopoutFilterPluginAssetTypeValues) => PopoutFilterPluginAssetTypeValues) => void,
};

/*
 * Popout filter plugin.
 * User can select asset type.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
const PopoutFilterPluginAssetTypeComponent = function PopoutFilterPluginAssetType({ value, onChange }: Props) {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginAssetTypeDisplayLookup).map(key => {
        const val = PopoutFilterPluginAssetTypeDisplayLookup[key];
        return (
          <FormControlLabel
            key={key}
            label={val}
            control={
              <Checkbox
                color="primary"
                inputProps={{
                  'aria-label': `${val}`,
                }}
                checked={!!value[key]}
                onChange={event => {
                  (value =>
                    onChange(prev => ({
                      ...prev,
                      // $FlowFixMe string -> enum
                      [key]: value,
                    })))(event.target.checked);
                }}
              />
            }
          />
        );
      })}
    </Box>
  );
};

export const PopoutFilterPluginAssetType = PopoutFilterPluginAssetTypeComponent;
