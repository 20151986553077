//@flow
import { createAction } from 'redux-actions';

import getKey from './getKey';
import type { PaginationResponse, Params, UnknownAction } from './types';

export function actionIsLoadMoreForType(action: UnknownAction, type: string): boolean {
  return action.type === paginationLoadMoreAction.toString() && actionIsForType(action, type);
}

export function actionIsForType(action: UnknownAction, type: string): boolean {
  return !!action.meta && typeof action.meta.type === 'string' && action.meta.type === type;
}

type MetaPayload = {|
  +type: string,
  +key: string,
|};

type Payload = Params;

const payloadCreator = (type, params) => params;
const metaCreator = (type, params) => ({
  type: type,
  key: getKey(params),
});

export const paginationMountedAction = createAction<'PAGINATION_MOUNTED', [string, Params], Payload, MetaPayload>(
  'PAGINATION_MOUNTED',
  payloadCreator,
  metaCreator,
);

export const paginationLoadMoreAction = createAction<'PAGINATION_LOAD_MORE', [string, Params], Payload, MetaPayload>(
  'PAGINATION_LOAD_MORE',
  payloadCreator,
  metaCreator,
);

export const paginationLoadedInitialAction = createAction<'PAGINATION_LOADED_INITIAL', [string, Params], MetaPayload>(
  'PAGINATION_LOADED_INITIAL',
  metaCreator,
);

export const paginationBeginRequest = createAction<'PAGINATION_REQUEST_BEGIN', [string, Params], MetaPayload>(
  'PAGINATION_REQUEST_BEGIN',
  metaCreator,
);

export const paginationEndRequest = createAction<
  'PAGINATION_REQUEST_END',
  [string, Params, PaginationResponse],
  {| ...MetaPayload, ...PaginationResponse |},
>('PAGINATION_REQUEST_END', (type, params, pagination_response) => ({
  ...metaCreator(type, params),
  ...pagination_response,
}));

export const paginationRequestFailed = createAction<'PAGINATION_REQUEST_FAILED', [string, Params], MetaPayload>(
  'PAGINATION_REQUEST_FAILED',
  metaCreator,
);
