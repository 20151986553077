// @flow
import React, { memo } from 'react';

import { palette } from '@dt/theme';

type Props = {
  foregroundColor?: string,
  backgroundColor?: string,
};

function DTIcon({ foregroundColor = palette.brand30, backgroundColor = palette.white }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.4"
      clipRule="evenodd"
      viewBox="0 0 249 246"
      width="100%"
      // This is important for IE 11
      style={{ maxHeight: 'inherit ' }}
    >
      <g fillRule="nonzero">
        <path
          fill={backgroundColor}
          d="M248.4 195.2a50.7 50.7 0 01-51 50.4H51c-28.2 0-51-22.6-51-50.4V50.4A50.6 50.6 0 0151 0h146.5c28.1 0 51 22.5 51 50.4v144.8z"
        ></path>
        <path
          fill={foregroundColor}
          d="M102.1 157.5a30.2 30.2 0 009.4-22.3c0-8.7-3.1-16-9.4-22.2a30.9 30.9 0 00-22.5-9.3c-8.8 0-16.3 3.1-22.5 9.3a30.2 30.2 0 00-9.4 22.2c0 8.7 3.1 16.2 9.4 22.3a30.9 30.9 0 0022.5 9.3c8.8 0 16.3-3.1 22.5-9.3m33.3-22.3c0 15.3-5.5 28.3-16.3 39a54.1 54.1 0 01-39.5 16.2 54 54 0 01-39.5-16.1 52.8 52.8 0 01-16.3-39c0-15.3 5.5-28.3 16.3-39A54 54 0 0179.6 80a54 54 0 0131.9 9.7V48.6h23.9v86.6zm39.6-31.5v31.5c0 8.7 3.2 16.2 9.4 22.3a30.9 30.9 0 0022.6 9.3v23.6a54 54 0 01-39.5-16.1 52.8 52.8 0 01-16.3-39V48.5H175V80h32v23.6h-32z"
        ></path>
      </g>
    </svg>
  );
}

export default memo<Props>(DTIcon);
