// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';
import qs from 'query-string';

import {
  type APIOperationsList,
  type CertificateChainList,
  type CertificateList,
  type CloudResourcesList,
  type CommentsList,
  type EventsList,
  type NetworkServicesList,
  type PaginatedResponse,
  type PolicyRuleList,
  type PolicyRuleTypeList,
  type PolicyViolationExceptionType,
  type PolicyViolationList,
  type RestfulAPIsList,
  type UsersList,
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type PolicyViolationListResponse = {|
  +policy_violations: PolicyViolationList,
  +policy_rules: PolicyRuleList,
  +policy_rule_types: PolicyRuleTypeList,
  +users: UsersList,
  +network_services: NetworkServicesList,
  +cloud_resources: CloudResourcesList,
  +restful_apis: RestfulAPIsList,
  +api_operations: APIOperationsList,
|};

export type PolicyViolationDetailsResponse = {|
  ...PolicyViolationListResponse,
  +events: EventsList,
  +comments: CommentsList,
  +certificate_chains: CertificateChainList,
  +certificates: CertificateList,
  +network_services: NetworkServicesList,
  +cloud_resources: CloudResourcesList,
  +restful_apis: RestfulAPIsList,
  +api_operations: APIOperationsList,
|};

export type PolicyViolationsListParams = {|
  +violated_policy_rule_id?: string,
  +filter_by_policy_rule_type_id?: Array<string>,
  +cursor?: string,
|};

export async function list(
  params: PolicyViolationsListParams,
): FetchResponse<PaginatedResponse<PolicyViolationListResponse>> {
  return fetch(`${api}/${version}/policy_violations?${qs.stringify(params || {})}`).then(parse);
}

export async function details(id: string): FetchResponse<PolicyViolationDetailsResponse> {
  return fetch(`${api}/${version}/policy_violations/${id}`).then(parse);
}

export type PolicyViolationPatchParams = {
  exception_type: PolicyViolationExceptionType,
  exception_explanation?: string,
  ...
};

export async function patch(
  id: string,
  payload: PolicyViolationPatchParams,
): FetchResponse<PolicyViolationDetailsResponse> {
  return fetch(`${api}/${version}/policy_violations/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  }).then(parse);
}

export type CommentPostParams = { content: string, ... };

export type CommentsListResponse = {|
  +comments: CommentsList,
  +users: UsersList,
|};

export const comments = {
  list: async function (PolicyViolationId: string): FetchResponse<CommentsListResponse> {
    return fetch(`${api}/${version}/policy_violations/${PolicyViolationId}/comments`).then(parse);
  },

  insert: async function (policyViolationId: string, payload: CommentPostParams) {
    return fetch(`${api}/${version}/policy_violations/${policyViolationId}/comments`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then(parse);
  },

  delete: async function (PolicyViolationId: string, commentId: string) {
    return fetch(`${api}/${version}/policy_violations/${PolicyViolationId}/comments/${commentId}`, {
      method: 'DELETE',
    }).catch(response => response.json());
  },
};
