// @flow
import React, { memo, useEffect, useState } from 'react';

import { Button, Card, List } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

import { useLazyQuery } from '@apollo/client';
import { ErrorState } from '@dt/components';
import assets_search_filter_values from '@dt/graphql-support/horizon/assets_search_filter_values';
import policy_rules from '@dt/graphql-support/horizon/policy_rules';
import mobile_phishing from '@dt/graphql-support/public/caprica/mobile_phishing';
import type {
  AssetsSearchFilterValuesQuery,
  AssetsSearchFilterValuesQueryVariables,
  DiscoveredAppsSearchFilterValuesQuery,
  DiscoveredAppsSearchFilterValuesQueryVariables,
  QueryAssets_Search_Filter_ValuesArgs,
} from '@dt/graphql-support/types';
import { SupplyChainSecureAppTypeEnumValues } from '@dt/graphql-support/types';
import { palette } from '@dt/theme';

import type { Filters } from '../TableFilters';
import TableFilterMyApps from './filters/TableFilterMyApps';
import TableFiltersAppType from './filters/TableFiltersAppType';
import TableFiltersAssetGroup from './filters/TableFiltersAssetGroup';
import TableFiltersAssetTag from './filters/TableFiltersAssetTag';
import TableFiltersAssetTypeGroup from './filters/TableFiltersAssetTypeGroup';
import TableFiltersFilterGroup from './filters/TableFiltersFilterGroup';
import TableFiltersKeyword from './filters/TableFiltersKeyword';
import TableFiltersListGroup from './filters/TableFiltersListGroup';
import TableFiltersSeverity from './filters/TableFiltersSeverity';
import TableFiltersShadowAssets from './filters/TableFiltersShadowAssets';
import TableFilterVendors from './filters/TableFilterVendors';
import TableFiltersSelectLabel from './TableFiltersSelectLabel';

type Props = {
  assetsSearchFilterValuesQueryParams?: QueryAssets_Search_Filter_ValuesArgs,
  filterEnums: Array<string>,
  filters: Filters,
  readOnlyFilters: Filters,
  isMobilePhishing: boolean,
  isPolicyRules: boolean,
  onClose: mixed => void,
  onApply: Filters => void,
};

export const filterItems = {
  filter_by_text: 'Keyword',
  filter_by_external_id: 'External ID',
  filter_by_asset_type: 'Asset Type',
  filter_by_related_first_party_asset_type: 'Asset Type',
  filter_by_app_type: 'App Type',
  filter_by_platform: 'Platform',
  filter_by_discovered_via: 'Discovered via',
  filter_by_asset_group_id: 'Asset Group',
  filter_by_vulnerable_to_toolkit_hacks: 'Hack Status',
  filter_by_active_protection_status: 'Active Protection Status',
  filter_by_cloud_provider: 'Hosted On',
  filter_by_asset_tags: 'Asset Tags',
  filter_by_is_shadow: 'Shadow Assets',
  filter_by_uses_third_party_vendor_id: 'Vendor',
  filter_by_mobile_app: 'Mobile App',
  filter_by_web_app: 'Web App',
  filter_by_related_first_party_asset_id: '',
  filter_by_asset_type_group: 'Third Party Asset Type',
  filter_by_discovered_application_keyword: 'Keyword',
  filter_by_infringement_level: 'Fraudulent Behavior',
  filter_by_discovered_app_status: 'App Status',
  filter_by_triaging_decision: 'Action',
  filter_by_severity: 'Severity',
  filter_by_has_policy_violations_with_status: 'Policy Violation Status',
  filter_by_subscription: 'Subscription',
  filter_by_belongs_to_products: 'Products',
};

let useStyles = makeStyles(theme => ({
  nav: {
    width: 350,
    borderRight: `1px solid ${palette.gray45}`,
  },
  list: {
    padding: 0,
    '& > li': {
      cursor: 'pointer',
      '&:hover': {
        background: palette.gray50,
      },
    },
  },
  box: {
    minHeight: 350,
    minWidth: 500,
    padding: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    borderTop: `1px solid ${palette.gray45}`,
  },
}));

const AssetFilterPopper = ({
  assetsSearchFilterValuesQueryParams,
  filterEnums,
  filters,
  readOnlyFilters,
  onClose,
  onApply,
  isMobilePhishing,
  isPolicyRules,
}) => {
  const classes = useStyles();

  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [FilterState, setFilterState] = useState<Filters>(filters);

  let { data, loading, error } = {};

  let [defaultQuery, { data: defaultData, loading: defaultLoading, error: defaultError }] = useLazyQuery<
    AssetsSearchFilterValuesQuery,
    AssetsSearchFilterValuesQueryVariables,
  >(assets_search_filter_values.list);

  let [mobilePhishingQuery, { data: mobilePhishingData, loading: mobilePhishingLoading, error: mobilePhishingError }] =
    useLazyQuery<DiscoveredAppsSearchFilterValuesQuery, DiscoveredAppsSearchFilterValuesQueryVariables>(
      mobile_phishing.discovered_apps_search_filter_values,
    );

  let [policyRulesQuery, { data: policyRulesData, loading: policyRulesLoading, error: policyRulesError }] =
    useLazyQuery(policy_rules.search_filter_values);

  useEffect(() => {
    if (isMobilePhishing) {
      mobilePhishingQuery();
    } else if (isPolicyRules) {
      policyRulesQuery();
    } else {
      defaultQuery({
        variables: {
          ...assetsSearchFilterValuesQueryParams,
        },
      });
    }
  }, [
    mobilePhishingQuery,
    policyRulesQuery,
    defaultQuery,
    assetsSearchFilterValuesQueryParams,
    isMobilePhishing,
    isPolicyRules,
  ]);

  loading = defaultLoading || mobilePhishingLoading || policyRulesLoading;
  data = defaultData || mobilePhishingData || policyRulesData;
  error = defaultError || mobilePhishingError || policyRulesError;

  let filterValues = {};
  if (isMobilePhishing) {
    filterValues =
      // $FlowFixMe
      data?.discovered_apps_search_filter_values.discovered_apps_search_filter_values;
  } else if (isPolicyRules) {
    filterValues =
      // $FlowFixMe
      data?.policy_rules_search_filter_values.policy_rules_search_filter_values;
  } else {
    filterValues =
      // $FlowFixMe
      data?.assets_search_filter_values.assets_search_filter_values;
  }

  if (error) {
    return (
      <Card>
        <Box p={1}>
          <ErrorState error={error.message} />
        </Box>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card>
        <Box>
          <Box display={'flex'}>
            <Box className={classes.nav} p={1}>
              {[...new Array(6)].map((_, key) => (
                <Skeleton key={key} animation="wave" width={`100%`} height={55} />
              ))}
            </Box>
            <Box className={classes.box}>
              <Skeleton animation="wave" width={`100%`} height={40} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Box ml={1}>
              <Skeleton animation="wave" width={75} height={48} />
            </Box>
            <Box ml={1}>
              <Skeleton animation="wave" width={75} height={48} />
            </Box>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <Card>
      <Box>
        <Box display={'flex'}>
          <Box className={classes.nav}>
            <List className={classes.list}>
              {filterEnums.map((item, key) => (
                <TableFiltersSelectLabel
                  title={filterItems[item]}
                  filterType={item}
                  selected={Boolean(selectedFilter === filterItems[item])}
                  disabled={Boolean(readOnlyFilters[item])}
                  onClick={() => {
                    !readOnlyFilters[item] && setSelectedFilter(item);
                  }}
                  key={key}
                />
              ))}
            </List>
          </Box>

          <Box className={classes.box}>
            {selectedFilter === 'filter_by_text' && (
              <TableFiltersKeyword
                filterKey={'filter_by_text'}
                label="Enter Keyword"
                filters={FilterState}
                onChange={filters => setFilterState(filters)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && onApply) {
                    onApply(FilterState);
                  }
                }}
              />
            )}

            {selectedFilter === 'filter_by_discovered_application_keyword' && (
              <TableFiltersKeyword
                filterKey={'filter_by_discovered_application_keyword'}
                label="Enter Keyword"
                filters={FilterState}
                onChange={filters => setFilterState(filters)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && onApply) {
                    onApply(FilterState);
                  }
                }}
              />
            )}

            {selectedFilter === 'filter_by_external_id' && (
              <TableFiltersKeyword
                filterKey={'filter_by_external_id'}
                label="Enter External ID"
                filters={FilterState}
                onChange={filters => setFilterState(filters)}
              />
            )}

            {selectedFilter === 'filter_by_asset_type' && (
              <TableFiltersListGroup
                searchLabel={'Search Asset Types'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'ASSET_TYPE')}
                selectedList={[...(FilterState.filter_by_asset_type || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_asset_type: values,
                  })
                }
              />
            )}

            {selectedFilter == 'filter_by_app_type' && (
              <TableFiltersAppType
                selectedList={FilterState.filter_by_asset_type || []}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_asset_type: ids,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_related_first_party_asset_type' && (
              <TableFiltersAppType
                selectedList={FilterState.filter_by_related_first_party_asset_type || []}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_related_first_party_asset_type: ids,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_platform' && (
              <TableFiltersFilterGroup
                searchLabel={'Search Platforms'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'MOBILE_APP_PLATFORM')}
                selectedList={[...(FilterState.filter_by_platform || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_platform: values,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_discovered_via' && (
              <TableFiltersFilterGroup
                searchLabel={'Search Discovery Sources'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'DISCOVERED_VIA')}
                selectedList={[...(FilterState.filter_by_discovered_via || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_discovered_via: values,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_cloud_provider' && (
              <TableFiltersFilterGroup
                searchLabel={'Search Hosting Providers'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'CLOUD_PROVIDER')}
                selectedList={[...(FilterState.filter_by_cloud_provider || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_cloud_provider: values,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_asset_group_id' &&
              typeof FilterState.filter_by_asset_group_id !== 'string' && (
                <TableFiltersAssetGroup
                  selectedAssetGroupIds={FilterState.filter_by_asset_group_id || []}
                  onChange={assetGroupIds =>
                    setFilterState({
                      ...FilterState,
                      filter_by_asset_group_id: assetGroupIds,
                    })
                  }
                />
              )}

            {selectedFilter === 'filter_by_is_shadow' && (
              <TableFiltersShadowAssets
                value={FilterState.filter_by_is_shadow || false}
                onChange={value =>
                  setFilterState({
                    ...FilterState,
                    filter_by_is_shadow: value,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_asset_tags' && (
              <TableFiltersAssetTag
                selectedAssetTagsEncoded={FilterState.filter_by_asset_tags || '{}'}
                onChange={assetTags =>
                  setFilterState({
                    ...FilterState,
                    filter_by_asset_tags: assetTags,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_active_protection_status' && (
              <TableFiltersFilterGroup
                searchLabel={'Search Active Protection Status'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'ACTIVE_PROTECTION_STATUS')}
                selectedList={[...(FilterState.filter_by_active_protection_status || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_active_protection_status: values,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_vulnerable_to_toolkit_hacks' && (
              <TableFiltersFilterGroup
                searchLabel={'Search by Hack Status'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'HACK_STATUS')}
                selectedList={[...(FilterState.filter_by_vulnerable_to_toolkit_hacks || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_vulnerable_to_toolkit_hacks: values,
                  })
                }
              />
            )}

            {selectedFilter == 'filter_by_uses_third_party_vendor_id' && (
              <TableFilterVendors
                selectedList={FilterState.filter_by_uses_third_party_vendor_id || []}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_uses_third_party_vendor_id: ids,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_infringement_level' && (
              <TableFiltersFilterGroup
                searchLabel={'Search Fraudulent Behavior'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'INFRINGEMENT_LEVEL')}
                selectedList={[...(FilterState.filter_by_infringement_level || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_infringement_level: values,
                  })
                }
              />
            )}

            {selectedFilter == 'filter_by_asset_type_group' && (
              <TableFiltersAssetTypeGroup
                selectedList={FilterState.filter_by_asset_type_group || []}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_asset_type_group: ids,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_discovered_app_status' && (
              <TableFiltersFilterGroup
                searchLabel={'Search App Status'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'DISCOVERED_APP_STATUS')}
                selectedList={[...(FilterState.filter_by_discovered_app_status || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_discovered_app_status: values,
                  })
                }
              />
            )}

            {selectedFilter == 'filter_by_mobile_app' && (
              <TableFilterMyApps
                appType={SupplyChainSecureAppTypeEnumValues.MOBILE_APPLICATION}
                selectedList={FilterState.filter_by_related_first_party_asset_id || []}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_related_first_party_asset_id: ids,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_triaging_decision' && (
              <TableFiltersFilterGroup
                searchLabel={'Search Action'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'TRIAGING_DECISION')}
                selectedList={[...(FilterState.filter_by_triaging_decision || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_triaging_decision: values,
                  })
                }
              />
            )}

            {selectedFilter == 'filter_by_web_app' && (
              <TableFilterMyApps
                appType={SupplyChainSecureAppTypeEnumValues.WEB_APPLICATION}
                selectedList={FilterState.filter_by_related_first_party_asset_id || []}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_related_first_party_asset_id: ids,
                  })
                }
              />
            )}

            {selectedFilter == 'filter_by_severity' && (
              <TableFiltersSeverity
                selectedList={FilterState.filter_by_severity || []}
                onChange={ids =>
                  setFilterState({
                    ...FilterState,
                    filter_by_severity: ids.map(id => id?.toUpperCase()),
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_has_policy_violations_with_status' && (
              <TableFiltersFilterGroup
                searchLabel={'Policy Violation Status'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'HAS_POLICY_VIOLATIONS_WITH_STATUS')}
                selectedList={[...(FilterState.filter_by_has_policy_violations_with_status || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_has_policy_violations_with_status: values,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_subscription' && (
              <TableFiltersFilterGroup
                searchLabel={'Subscription'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'MOBILE_APP_SUBSCRIPTION')}
                selectedList={[...(FilterState.filter_by_subscription || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_subscription: values,
                  })
                }
              />
            )}

            {selectedFilter === 'filter_by_belongs_to_products' && (
              <TableFiltersFilterGroup
                searchLabel={'Products'}
                list={filterValues?.filter(item => item?.belongs_to_filter === 'BELONGS_TO_PRODUCT')}
                selectedList={[...(FilterState.filter_by_belongs_to_products || [])]}
                onChange={values =>
                  setFilterState({
                    ...FilterState,
                    filter_by_belongs_to_products: values,
                  })
                }
              />
            )}
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Box>
            <Button variant={'text'} onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => onApply(FilterState)}
              disabled={filters === FilterState}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default memo<Props>(AssetFilterPopper);
