//@flow
import getKey from './getKey';
import type { PaginationInfo, Params, State } from './types';

export function createSelectorForPaginationInfo(type: string, params: Params) {
  const key = getKey(params);
  return (state: State): PaginationInfo =>
    (state.pagination.requests[type] && state.pagination.requests[type][key]) || {
      loadedInitial: false,
      loading: false,
    };
}
