//@flow
import React, { memo, type Node } from 'react';
import { NavLink, Route } from 'react-router-dom';

import { type Properties } from 'csstype';

import ExtLink from './ExtLink';

type Props = {
  children: Node,
  to: string,
  exact?: boolean,
  strict?: boolean,
  replace?: boolean,
  className?: string,
  redirect?: boolean,
  target?: '_self' | '_blank' | '_parent' | '_top',
  onClick?: () => void,
  activeStyle: Properties<>,
};

function MobileNavLink({
  children,
  activeStyle,
  to,
  exact = false,
  redirect = false,
  target = '_blank',
  strict,
  replace,
  ...rest
}: Props) {
  return redirect ? (
    <ExtLink {...rest} to={to} target={redirect ? target : '_self'}>
      {children}
    </ExtLink>
  ) : (
    <Route path={to} exact={exact} strict={strict}>
      {() => {
        if (to) {
          return (
            <NavLink {...rest} to={to} replace={replace} style={{ display: 'block' }}>
              {children}
            </NavLink>
          );
        }

        return <>{children}</>;
      }}
    </Route>
  );
}

export default memo<Props>(MobileNavLink);
