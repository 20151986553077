// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';
import qs from 'query-string';

import { type AssetGroupList } from './asset_groups';
import {
  type PaginatedResponse,
  type PolicyList,
  type PolicyRule,
  type PolicyRuleConfiguration,
  type PolicyRuleList,
  type PolicyRuleTypeList,
  type PolicyRuleTypeRelevanceType,
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type PoliciesListResponce = {
  policies: PolicyList,
  policy_rules: PolicyRuleList,
  policy_rule_types: PolicyRuleTypeList,
};

export async function list(params: { ... }): FetchResponse<PaginatedResponse<PoliciesListResponce>> {
  return fetch(`${api}/${version}/policies?${qs.stringify(params)}`).then(parse);
}

export type PoliciesDetailsResponse = {|
  +policies: PolicyList,
  +policy_rules: PolicyRuleList,
  +policy_rule_types: PolicyRuleTypeList,
  +asset_groups: AssetGroupList,
|};

export async function details(policy_id: string): FetchResponse<PoliciesDetailsResponse> {
  return fetch(`${api}/${version}/policies/${policy_id}`).then(parse);
}

type PolicyCreateParams = {|
  name: string,
  description: string,
|};

export async function create(params: PolicyCreateParams): FetchResponse<PoliciesDetailsResponse> {
  return fetch(`${api}/${version}/policies`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export async function remove(policy_id: string): Promise<boolean> {
  return fetch(`${api}/${version}/policies/${policy_id}`, {
    method: 'DELETE',
  }).then(r => r.ok);
}

export type PolicyRuleCreateParams = {
  policy_rule_type_id: string,
  relevance?: PolicyRuleTypeRelevanceType,
  configuration: null | PolicyRuleConfiguration | void,
};

export type PolicyRulePatchParams = {
  relevance?: PolicyRuleTypeRelevanceType,
  configuration?: PolicyRuleConfiguration,
};

export const policy_rules = {
  create: (policy_id: string, policy_rule: PolicyRuleCreateParams): FetchResponse<PolicyRule> => {
    return fetch(`${api}/${version}/policies/${policy_id}/policy_rules`, {
      method: 'POST',
      body: JSON.stringify(policy_rule),
    }).then(parse);
  },
  patch: (policy_id: string, policy_rule_id: string, params: PolicyRulePatchParams) => {
    return fetch(`${api}/${version}/policies/${policy_id}/policy_rules/${policy_rule_id}`, {
      body: JSON.stringify(params),
      method: 'PATCH',
    }).then(parse);
  },
  delete: (
    policy_id: string,
    policy_rule_id: string,
    params: {
      deletion_explanation?: string,
      ...
    },
  ) => {
    return fetch(
      `${api}/${version}/policies/${policy_id}/policy_rules/${policy_rule_id}${
        Object.keys(params).length ? '' : `?${qs.stringify(params)}`
      }`,
      {
        method: 'DELETE',
      },
    ).catch(response => response.json());
  },
};
