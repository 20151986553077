// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';
import qs from 'query-string';

import { type CloudResourcesList } from './types';
import {
  type EventsList,
  type PaginatedResponse,
  type PolicyRuleList,
  type PolicyRuleTypeList,
  type PolicyViolationList,
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type CloudResourcesListResponse = {|
  cloud_resources: CloudResourcesList,
  policy_violations: PolicyViolationList,
  policy_rules: PolicyRuleList,
  policy_rule_types: PolicyRuleTypeList,

  events?: EventsList,
|};

export type CloudResourceDetailsResponse = {|
  ...CloudResourcesListResponse,
  events: EventsList,
|};

export async function list(params?: { ... }): FetchResponse<PaginatedResponse<CloudResourcesListResponse>> {
  return fetch(`${api}/${version}/cloud_resources?${qs.stringify(params || {})}`).then(parse);
}

export async function details(id: string): FetchResponse<CloudResourcesListResponse> {
  return fetch(`${api}/${version}/cloud_resources/${id}`).then(parse);
}
