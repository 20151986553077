// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

type SupportRequestParams = {
  subject: string,
  body?: string,
};

type SupportRequestResponse = {};

export async function create(params: SupportRequestParams): FetchResponse<SupportRequestResponse> {
  return fetch(`${api}/${version}/support_requests`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
