// @flow
import config from '@dt/config';
import fetch, { type APIResult as FetchResponse, parse } from '@dt/fetch';
import qs from 'query-string';

import type { IPRange, IPRangeList, PaginatedResponse } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type IPRangesListResponse = PaginatedResponse<{|
  +ip_ranges: IPRangeList,
|}>;

export async function list(params: { ... }): FetchResponse<IPRangesListResponse> {
  return fetch(`${api}/${version}/ip_ranges?${qs.stringify(params)}`).then(parse);
}

export type IPRangeCreateParams = {|
  +ip_range: string,
  +has_continuous_discovery_enabled?: boolean,
  +ip_addresses_to_import?: ?$ReadOnlyArray<string>,
|};

export type IPRangeCreateResponse = {|
  +ip_ranges: IPRangeList,
|};

export async function create(params: IPRangeCreateParams): FetchResponse<IPRangeCreateResponse> {
  if (params.has_continuous_discovery_enabled && params.ip_addresses_to_import !== null) {
    throw new Error(
      "Unable to create ip address range. Unable to import 'ip_addresses_to_import' when 'has_continuous_discovery_enabled' is enabled.",
    );
  }

  return fetch(`${api}/${version}/ip_ranges`, {
    method: 'POST',
    body: JSON.stringify({
      ...params,
      ip_addresses_to_import: params.ip_addresses_to_import !== null ? params.ip_addresses_to_import : [],
    }),
  }).then(parse);
}

export async function get(id: string): FetchResponse<IPRange> {
  return fetch(`${api}/${version}/ip_ranges/${id}`).then(parse);
}

export type IPRangePatchParams = {|
  +has_continuous_discovery_enabled: boolean,
|};

export type IPRangePatchResponse = {|
  +ip_ranges: IPRangeList,
|};

export async function patch(id: string, params: IPRangePatchParams): FetchResponse<IPRangePatchResponse> {
  return fetch(`${api}/${version}/ip_ranges/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  }).then(parse);
}
